import { hash } from 'ohash'
import type { UseAppFetchOptions } from '~/composables/useAppFetch'
import unwrapSearchParameters from '~/helpers/fetch/unwrapSearchParameters'

export default (request: string, options: UseAppFetchOptions): string => {
    if (options.key) {
        return options.key
    }

    const mergedValues = { ...unwrapSearchParameters(options.params), ...unwrapSearchParameters(options.query) }
    const body = isRef(options.body) ? toValue(options.body) : options.body

    if (body instanceof FormData) {
        for (const key of body.keys()) {
            const value = body.get(key)

            if (isString(value)) {
                mergedValues[key] = value
            } else {
                mergedValues[key] = value?.name || value
            }
        }
    } else if (options.body) {
        mergedValues[JSON.stringify(options.body)] = ''
    }

    return hash([ 'app-fetch', options.method || 'GET', request, mergedValues ])
}
