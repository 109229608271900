import type { AppTablePagination } from '~/ts/types/app'
import type * as projectTypes from '~/ts/types/project'

export default {
    all({ page, perPage = APP_DEFAULT_PER_PAGE, expand }: projectTypes.ProjectAll) {
        return useAppFetch<{ items: projectTypes.Project[], _meta: AppTablePagination }>('/project', {
            params: {
                page,
                'per-page': perPage,
                expand: getFilledKeysString(expand)
            }
        })
    },
    one({ id }: projectTypes.ProjectOne) {
        return useAppFetch<projectTypes.Project>(`/project/${ id }`, {
            params: { id }
        })
    },
    create(data: projectTypes.ProjectCreate) {
        return useAppFetch<projectTypes.Project>('/project', {
            method: 'post',
            body: data
        })
    },
    update({ id, data }: projectTypes.ProjectUpdate) {
        return useAppFetch<projectTypes.Project>('/project/' + id, {
            method: 'put',
            body: data
        })
    },
    updateLogo({ id, data }: projectTypes.ProjectUpdateLogo) {
        return useAppFetch<{ file: projectTypes.Project['logo'] }>(`/project/${ id }/logo`, {
            method: 'post',
            body: data
        })
    },
    deleteLogo({ id }: projectTypes.ProjectDeleteLogo) {
        return useAppFetch(`/project/${ id }/logo`, {
            method: 'delete'
        })
    },
    delete({ id }: projectTypes.ProjectDelete) {
        return useAppFetch('/project/' + id, {
            method: 'delete'
        })
    }
}
