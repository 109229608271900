import type { AnyObj } from '~/ts/types/common'

export const getFilledKeysString = (value: AnyObj, separator = ','): string | undefined => {
    if (!isObject(value)) {
        return
    }

    return Object
        .keys(value)
        .filter(key => value[key])
        .join(separator)
}
