import type { EventHook } from '~/utils/createEventHook'
import type { BroadcasterChannelOptions } from '~/helpers/ws/BroadcasterChannel'
import { BroadcasterChannel } from '~/helpers/ws/BroadcasterChannel'
import { BroadcasterMessageHandler } from '~/helpers/ws/BroadcasterMessageHandler'
import { Loggable } from '~/helpers/Loggable'

type Reconnection = {
    auto: boolean
    timer: ReturnType<typeof setTimeout> | undefined
    attempt: number
}

type Hook = 'onConnected' | 'onDisconnected' | 'onReconnected' | 'onFetchTokenFailed'

type Options = {
    immediateConnection?: boolean
    autoReconnection?: boolean
}

export class Broadcaster extends Loggable {
    public ws: WebSocket | undefined
    public readonly socketId = ref<string | undefined>()
    public readonly channels = new Map<string, BroadcasterChannel>()
    public readonly isConnecting = ref<boolean>(false)
    public readonly isConnected = ref<boolean>(false)
    public readonly wasDisconnected = ref<boolean>(false)

    private readonly wsUrl: string = useRuntimeConfig().public.wsUrl

    private reconnection: Reconnection = {
        auto: false,
        timer: undefined,
        attempt: 0
    }

    private readonly hooks: Record<Hook, EventHook> = {
        onConnected: createEventHook(),
        onDisconnected: createEventHook(),
        onReconnected: createEventHook(),
        onFetchTokenFailed: createEventHook()
    }

    constructor({ immediateConnection, autoReconnection }: Options = {}) {
        super({
            disabled: true,
            defaultLogOptions: {
                hint: true,
                devOnly: true
            }
        })

        this.reconnection.auto = autoReconnection || false

        immediateConnection && this.connect()
    }

    public updateOptions = ({ autoReconnection }: Omit<Options, 'immediateConnection'>): void => {
        this.reconnection.auto = autoReconnection
    }

    public connect = (): void => {
        this.log(`(old connected: ${ this.isConnected.value })`, 'Broadcaster->connect', { hint: true })

        if (this.isConnected.value || this.isConnecting.value) {
            return
        }

        this.isConnecting.value = true

        useApi().auth.wsAuth()
            .then(({ data, error }) => {
                if (error.value) {
                    this.log(
                        'Не вдалось оновити токен для з’єднання з сервером',
                        'Broadcaster->connect',
                        { error: true }
                    )

                    this.hooks.onFetchTokenFailed.trigger()

                    return
                }

                this.ws = new WebSocket(this.wsUrl + '?token=' + data.value.access_token)

                this.socketId.value = data.value.access_token

                this.ws.onopen = this.onOpen.bind(this)
                this.ws.onclose = this.onClose.bind(this)
                this.ws.onerror = this.onError.bind(this)
            })
    }

    public asyncConnect = ({ waitOneAttempt = !this.reconnection.auto, waitMaxMs = 0 } = {}): Promise<void> => {
        return new Promise((resolve) => {
            const hookCleanUps = []

            const resolveAndCleanUp = (): void => {
                resolve()

                hookCleanUps.forEach(fn => fn())
            }

            if (waitOneAttempt) {
                hookCleanUps.push(this.onDisconnected(resolveAndCleanUp))
            }

            if (waitMaxMs) {
                const timer = setTimeout(resolveAndCleanUp, waitMaxMs)

                hookCleanUps.push(() => clearTimeout(timer))
            }

            hookCleanUps.push(this.onConnected(resolveAndCleanUp))

            this.connect()
        })
    }

    public disconnect = (): void => {
        this.log(`(old connected: ${ this.isConnected.value })`, 'Broadcaster->disconnect')

        this.cleanUpReconnecting()

        if (!this.isConnected.value) {
            return
        }

        this.ws.close(WS_CLOSING_CODE_MANUAL, 'Manual closing')

        this.ws = undefined
    }

    public createChannel = (name: string, channelOptions?: BroadcasterChannelOptions): BroadcasterChannel => {
        this.log(`(name: ${ name })`, 'Broadcaster->createChannel')

        if (!this.channels.has(name)) {
            this.channels.set(name, new BroadcasterChannel(this, name, channelOptions))
        }

        return this.channels.get(name)
    }

    public onConnected = (fn: VoidFunction): VoidFunction => this.hooks.onConnected.on(fn)
    public onDisconnected = (fn: VoidFunction): VoidFunction => this.hooks.onDisconnected.on(fn)
    public onReconnected = (fn: VoidFunction): VoidFunction => this.hooks.onReconnected.on(fn)
    public onFetchTokenFailed = (fn: VoidFunction): VoidFunction => this.hooks.onFetchTokenFailed.on(fn)

    private reconnect = (): void => {
        this.log(`(old attempt: ${ this.reconnection.attempt })`, 'Broadcaster->reconnect', { hint: true })

        if (this.reconnection.attempt >= WS_RECONNECTING_MAX_ATTEMPTS) {
            this.disconnect()

            return
        }

        this.reconnection.attempt++

        this.log(
            `Спроба перепідключення - ${ this.reconnection.attempt }/${ WS_RECONNECTING_MAX_ATTEMPTS }`,
            'Broadcaster->reconnect',
            { warning: true, devOnly: true }
        )

        this.reconnection.timer = setTimeout(this.connect.bind(this), WS_RECONNECTING_DELAY)
    }

    private reSubscribeToChannels = (): void => {
        this.log(`(size: ${ this.channels.size }, канали з ручним підключенням не підключаються автоматично)`, 'Broadcaster->reSubscribeToChannels')

        this.channels.forEach((channel) => {
            !channel.isManualSubscription && channel.subscribe()
        })
    }

    private cleanUpReconnecting = (): void => {
        this.log(`(old attempt: ${ this.reconnection.attempt })`, 'Broadcaster->cleanUpReconnecting')

        clearTimeout(this.reconnection.timer)

        this.reconnection.timer = undefined
        this.reconnection.attempt = 0
    }

    private onOpen(): void {
        this.isConnected.value = (this.ws.readyState === WebSocket.OPEN)

        if (!this.isConnected.value) {
            setTimeout(() => this.onOpen(), 50)

            return
        }

        this.isConnecting.value = false

        this.log('Зʼєднання з сервером встановлено', 'Broadcaster->onOpen', { devOnly: true })

        const isReconnected = this.reconnection.auto && !!this.reconnection.attempt

        this.cleanUpReconnecting()

        this.ws.onmessage = BroadcasterMessageHandler.handleMessage.bind(this, this, this.log)

        this.reSubscribeToChannels()

        this.hooks.onConnected.trigger()
        isReconnected && this.hooks.onReconnected.trigger()
    }

    private onClose = (event: CloseEvent): void => {
        this.isConnecting.value = false
        this.isConnected.value = false
        this.wasDisconnected.value = true
        this.socketId.value = undefined

        const isManualClosing = event.code === WS_CLOSING_CODE_MANUAL

        this.log(
            'З’єднання з сервером розірвано',
            'Broadcaster->onClose',
            { warning: !isManualClosing }
        )

        this.channels.forEach((channel) => {
            !channel.isManualSubscription && channel.unsubscribe()
        })

        this.hooks.onDisconnected.trigger()

        if (isManualClosing || !this.reconnection.auto) {
            return
        }

        this.reconnect()
    }

    private onError = (): void => {
        this.log(
            'Помилка зʼєднання з сервером',
            'Broadcaster->onError',
            { error: true }
        )
    }
}
