<script
    lang="ts"
    setup
>
    type Props = {
        size?: number | string
        color?: string,
        viewBox?: boolean
        withoutSizes?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        size: 59,
        color: '#aaadb8',
        viewBox: true,
        withoutSizes: false
    })

    const style = computed<string>(() => `min-width: ${ props.size }px; min-height: ${ props.size }px;`)
</script>

<template>
    <!-- eslint-disable max-len -->
    <svg
        :width="props.size"
        :height="props.withoutSizes ? undefined : props.size"
        :viewBox="props.viewBox ? '0 0 59 10' : null"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="props.withoutSizes ? undefined : style"
    >
        <path
            d="M5.5369 2.93112C5.49926 2.5479 5.33775 2.2502 5.05236 2.038C4.76697 1.82581 4.37965 1.71971 3.89041 1.71971C3.55798 1.71971 3.27729 1.76722 3.04835 1.86223C2.81941 1.95408 2.64379 2.08234 2.52148 2.24703C2.4023 2.41172 2.34271 2.59858 2.34271 2.8076C2.33644 2.98179 2.37251 3.13381 2.45091 3.26366C2.53245 3.39351 2.64379 3.50594 2.78491 3.60095C2.92604 3.6928 3.08912 3.77356 3.27415 3.84323C3.45919 3.90974 3.65677 3.96675 3.86689 4.01425L4.73247 4.22328C5.15272 4.31829 5.53846 4.44497 5.88971 4.60333C6.24096 4.76168 6.54517 4.95645 6.80234 5.18765C7.0595 5.41884 7.25865 5.69121 7.39978 6.00475C7.54404 6.31829 7.61774 6.67775 7.62088 7.08314C7.61774 7.67854 7.4672 8.19477 7.16927 8.63183C6.87447 9.06572 6.44795 9.40301 5.88971 9.64371C5.33461 9.88124 4.66504 10 3.881 10C3.10323 10 2.42582 9.87965 1.84877 9.63896C1.27485 9.39826 0.826379 9.04196 0.503354 8.57007C0.183466 8.09501 0.0156808 7.50752 0 6.8076H1.97108C1.99303 7.13381 2.08555 7.40618 2.24863 7.6247C2.41485 7.84006 2.63595 8.00317 2.91193 8.11402C3.19105 8.2217 3.50623 8.27553 3.85748 8.27553C4.20246 8.27553 4.50196 8.22486 4.75599 8.12352C5.01316 8.02217 5.2123 7.88124 5.35343 7.70071C5.49456 7.52019 5.56512 7.31275 5.56512 7.07839C5.56512 6.85986 5.50083 6.67617 5.37225 6.52732C5.2468 6.37847 5.06177 6.25178 4.81715 6.14727C4.57566 6.04276 4.27929 5.94774 3.92804 5.86223L2.879 5.5962C2.06673 5.39668 1.42539 5.08472 0.954962 4.66033C0.484537 4.23595 0.250893 3.66429 0.254029 2.94537C0.250893 2.35629 0.406133 1.84165 0.71975 1.40143C1.0365 0.961204 1.47086 0.617577 2.02283 0.370546C2.57479 0.123515 3.20202 0 3.90452 0C4.61957 0 5.24366 0.123515 5.77681 0.370546C6.3131 0.617577 6.73021 0.961204 7.02814 1.40143C7.32608 1.84165 7.47975 2.35154 7.48916 2.93112H5.5369Z"
            :fill="props.color"
        />

        <path
            d="M8.99099 9.86223V0.133017H25.1988C25.9295 0.133017 26.552 0.273951 27.0663 0.55582C27.5807 0.834521 27.9727 1.22249 28.2424 1.71971C28.5153 2.21378 28.6517 2.78385 28.6517 3.42993C28.6517 4.07601 28.5137 4.64608 28.2377 5.14014C27.9617 5.6342 27.5619 6.019 27.0381 6.29454C26.5175 6.57007 25.8871 6.70784 25.147 6.70784H13.4127L11.0279 9.86223V7.2L12.6369 5.05938H24.8177C25.2097 5.05938 25.5328 4.99129 25.7868 4.85511C26.044 4.71576 26.2353 4.52415 26.3607 4.28029C26.4893 4.03325 26.5536 3.7498 26.5536 3.42993C26.5536 3.10689 26.4893 2.82502 26.3607 2.58432C26.2353 2.34046 26.044 2.15202 25.7868 2.019C25.5296 1.88282 25.2035 1.81473 24.8083 1.81473H11.0279V9.86223H8.99099Z"
            :fill="props.color"
        />

        <path
            d="M32.0117 0.133017V9.86223H29.9747V0.133017H32.0117Z"
            :fill="props.color"
        />

        <path
            d="M33.6876 9.86223V0.133017H35.7245V8.16627H39.8548V9.86223H33.6876Z"
            :fill="props.color"
        />

        <path
            d="M41.2038 9.86223V0.133017H43.2407V4.4228H43.3677L46.8348 0.133017H49.2763L45.701 4.48931L49.3186 9.86223H46.8818L44.2427 5.86223L43.2407 7.09739V9.86223H41.2038Z"
            :fill="props.color"
        />

        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M53.0029 7.6332L49.7102 0.0996094H51.8888L54.0448 5.05528L56.0478 0.0996126L58.2263 0.0996188L54.3644 9.89961H52.0868L53.0029 7.6332Z"
            :fill="props.color"
        />
    </svg>
    <!-- eslint-enable max-len -->
</template>
