type UseWindowFocus = {
    isWindowFocused: Ref<boolean>
    onWindowFocus: (fn: Function) => VoidFunction
    onWindowBlur: (fn: Function) => VoidFunction
}

type UseWindowFocusOptions = {
    listen?: boolean
}

export const useWindowFocus = (options: UseWindowFocusOptions = {}): UseWindowFocus => {
    const {
        listen = true
    } = options

    const { window, document } = getClientContext()

    const isWindowFocused = ref<boolean>(false)

    if (!window) {
        return {
            isWindowFocused,
            onWindowFocus: () => noop,
            onWindowBlur: () => noop
        }
    }

    const hooks = {
        onWindowFocus: createEventHook(),
        onWindowBlur: createEventHook()
    }

    const handler = (): void => {
        isWindowFocused.value = document.hasFocus()

        listen && hooks[isWindowFocused.value ? 'onWindowFocus' : 'onWindowBlur'].trigger()
    }

    handler()

    if (listen) {
        onEvent(window, 'focus', handler)
        onEvent(window, 'blur', handler)
    }

    return {
        isWindowFocused,
        onWindowFocus: hooks.onWindowFocus.on,
        onWindowBlur: hooks.onWindowBlur.on
    }
}
