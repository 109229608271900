import type { SetAuthTokenContext } from '~/helpers/fetch/setAuthToken'

export default async ({ options, token, userStore }: SetAuthTokenContext): Promise<boolean> => {
    const refreshToken = useAppCookie<string>('refreshToken')

    if (!refreshToken.value) {
        !options.preventLogout && await userStore.logout()

        return false
    }

    const refreshTokenLock = useState('refreshTokenLock', () => {
        return shallowRef(createLock(() => {
            return useApi().auth.refreshToken({
                refreshToken: refreshToken.value
            })
        }))
    })

    const { data, error } = await refreshTokenLock.value.request()

    if (error.value) {
        if ([ 403, 404 ].includes(error.value.statusCode)) {
            !options.preventLogout && await userStore.logout()
        }

        return false
    }

    token.value = data.value['access_token']
    refreshToken.value = data.value['refresh_token']

    options.headers['Authorization'] = `Bearer ${ token.value }`

    return true
}
