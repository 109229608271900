import { ChatKindEnum, ChatMessageTypeEnum, ChatStatusEnum } from '~/ts/enums/chat'

export const CHAT_ARCHIVE_LIST_PER_PAGE = 30
export const CHAT_MESSAGES_PER_PAGE = 30
export const CHAT_SCROLL_BODY_ANCHOR_TOP = 18
export const CHAT_SCROLL_BOTTOM_THRESHOLD = 50
export const CHAT_SCROLL_DOWN_BUTTON_THRESHOLD = 300
export const CHAT_UNREAD_DIVIDER_ID = 'chat-conversation-body-unread-divider'
export const CHAT_KINDS: readonly string[] = Object.freeze(Object.values(ChatKindEnum))
export const CHAT_ARCHIVE_STATUSES: readonly ChatStatusEnum[] = Object.freeze([
    ChatStatusEnum.Closed,
    ChatStatusEnum.Missed,
    ChatStatusEnum.Rejected
])
export const CHAT_MESSAGE_MEDIA_TYPES: readonly ChatMessageTypeEnum[] = Object.freeze([
    ChatMessageTypeEnum.Image,
    ChatMessageTypeEnum.Gif
])
