<script
    lang="ts"
    setup
>
    import type { NuxtError } from '#app'
    import type { FetchError } from 'ofetch'
    import { useUserStore } from '~/stores/user'

    type Props = {
        error: NuxtError | FetchError | Error | any
    }

    enum ActionType {
        Logout = 'logout',
        Reload = 'reload',
        Back = 'back'
    }

    const DEFAULT_ERROR_STATUS_CODE = 500
    const TRANSLATED_ERROR_CODES = [ 401, 403, 404, 429, 500 ]

    const props = defineProps<Props>()

    const { public: { isProd } } = useRuntimeConfig()
    const userStore = useUserStore()

    const statusCode = computed<number>(() => +props.error?.statusCode || DEFAULT_ERROR_STATUS_CODE)

    const { t } = useLang()

    const errorDescription = computed<string>(() => {
        if (TRANSLATED_ERROR_CODES.includes(statusCode.value)) {
            return t(`error-${ statusCode.value }-description`)
        }

        return t(`error-${ DEFAULT_ERROR_STATUS_CODE }-description`)
    })

    const errorMessage = computed<string>(() => {
        if (TRANSLATED_ERROR_CODES.includes(statusCode.value)) {
            return t(`error-${ statusCode.value }-message`)
        }

        return t(`error-${ DEFAULT_ERROR_STATUS_CODE }-message`)
    })

    const actionType = computed<ActionType>(() => {
        switch (statusCode.value) {
            case 401:
                return ActionType.Logout
            case 500:
                return ActionType.Reload
            default:
                return ActionType.Back
        }
    })

    const actionIcon = computed<ReturnType<typeof defineComponent>>(() => {
        return {
            [ActionType.Logout]: resolveComponent('AppIconArrowRight'),
            [ActionType.Reload]: resolveComponent('AppIconRefresh'),
            [ActionType.Back]: resolveComponent('AppIconArrowLeft')
        }[actionType.value]
    })

    const actionText = computed<string>(() => {
        return {
            [ActionType.Logout]: t('login'),
            [ActionType.Reload]: t('reload-page'),
            [ActionType.Back]: t('back')
        }[actionType.value]
    })

    const onAction = (): void => {
        switch (actionType.value) {
            case ActionType.Logout:
                userStore.logout()
                break
            case ActionType.Reload:
                location.reload()
                break
            default:
                location.replace('/')
        }
    }

    onBeforeMount(() => {
        useLog(props.error, 'ErrorPage', {
            error: true,
            devOnly: true
        })
    })
</script>

<template>
    <div class="flex flex-col items-center h-full px-4 bg-white text-black">
        <Title>
            {{ errorDescription }}
        </Title>

        <AppBranding
            key="branding"
            size="80"
            color="#000"
            class="my-12"
        />

        <div
            key="info"
            class="flex flex-col my-10 items-center"
        >
            <div class="text-[80px] font-medium">
                {{ statusCode }}
            </div>

            <div class="text-[40px] text-center font-medium">
                {{ errorDescription }}
            </div>

            <div class="mt-[36px] text-[16px] text-center">
                {{ errorMessage }}
            </div>
        </div>

        <AppButton
            key="action"
            class="mt-5"
            @click="onAction()"
        >
            <component
                :is="actionIcon"
                size="16"
                color="#000"
                class="mr-2"
            />

            {{ actionText }}
        </AppButton>

        <div
            v-if="!isProd"
            key="dev-details"
            class="
                flex
                flex-col
                gap-2
                overflow-auto
                max-w-full
                mt-15
                py-4
                px-6
                border-2
                border-black
                text-black
                rounded-xl
            "
        >
            <div class="text-[20px]">
                {{ props.error?.message || '-' }}
            </div>

            <!-- eslint-disable vue/no-v-html -->
            <div
                class="text-[14px]"
                v-html="props.error?.stack || props.error"
            ></div>
            <!-- eslint-enable vue/no-v-html -->
        </div>
    </div>
</template>
