import type { AppConfirmContext } from '~/ts/types/app'

export const useConfirmContext = (): Ref<AppConfirmContext> => {
    return useState<AppConfirmContext>('confirmContext', () => ({ callback: undefined }))
}

export const useConfirm = (context?: Omit<AppConfirmContext, 'callback'>): Promise<boolean> => {
    return new Promise((resolve) => {
        const confirmContext = useConfirmContext()

        if (context) {
            Object.assign(confirmContext.value, context)
        }

        confirmContext.value.callback = resolve
    })
}
