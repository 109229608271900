export type EventBusIdentifier = string | number
export type EventBusListener<T = any> = (payload?: T) => void
export type EventBusEvents<T = any> = Map<string, Set<EventBusListener<T>>>

export type UseEventBus = {
    on: <T = any>(event: string, listener: EventBusListener<T>) => VoidFunction
    once: <T = any>(event: string, listener: EventBusListener<T>) => VoidFunction
    emit: <T = any>(event: string, payload?: T) => void
    off: (event: string, listener: EventBusListener) => void
    reset: () => void
}

export const eventBusListeners = new Map<EventBusIdentifier, EventBusEvents>()

export const useEventBus = (key: EventBusIdentifier = 'default'): UseEventBus => {
    const scope = getCurrentScope()

    const reset = (): void => {
        eventBusListeners.delete(key)
    }

    const off = (event: string, listener: EventBusListener): void => {
        const events = eventBusListeners.get(key)

        if (!events) {
            return
        }

        const listeners = events.get(event)

        if (!listeners) {
            return
        }

        listeners.delete(listener)

        if (!listeners.size) {
            events.delete(event)

            if (!events.size) {
                reset()
            }
        }
    }

    const on = <T = any>(event: string, listener: EventBusListener<T>): VoidFunction => {
        const events = eventBusListeners.get(key) || new Map()
        const listeners = events.get(event) || new Set()

        listeners.add(listener)

        events.set(event, listeners)
        eventBusListeners.set(key, events)

        const _off = () => off(event, listener)

        // @ts-ignore
        scope?.cleanups?.push(_off)

        return _off
    }

    const once = <T = any>(event: string, listener: EventBusListener<T>): VoidFunction => {
        const _listener = (...args: any[]): void => {
            off(event, _listener)

            // @ts-ignore
            listener(...args)
        }

        return on<T>(event, _listener)
    }

    const emit = <T = any>(event, payload?: T): void => {
        eventBusListeners.get(key)?.get(event)?.forEach(cb => cb(payload))
    }

    return {
        on,
        once,
        off,
        emit,
        reset
    }
}
