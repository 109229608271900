import { defineStore } from 'pinia'
import type { RouteLocationNamedRaw, RouteLocationNormalizedLoaded } from 'vue-router'
import type { QuickAnswer, QuickAnswerTag } from '~/ts/types/quick-answer'
import type { KBArticle, KBItem } from '~/ts/types/knowledge-base'
import type { FormSelectOption } from '~/ts/types/form'
import type { Operator } from '~/ts/types/operator'
import type { CChannel } from '~/ts/types/communication-channel'
import type * as chatTypes from '~/ts/types/chat'
import { WSClientChannelEventEnum } from '~/ts/enums/ws'
import { ChatKindEnum, ChatMemberUserTypeEnum, ChatMessageTypeEnum, ChatStatusEnum } from '~/ts/enums/chat'
import { useUserStore } from '~/stores/user'
import { useLanguageStore } from '~/stores/language'
import { BroadcastManager } from '~/helpers/chat/BroadcastManager'
import { TypingHandler } from '~/helpers/chat/TypingHandler'
import { MessageSendingQueue } from '~/helpers/chat/MessageSendingQueue'
import groupMessages from '~/helpers/chat/groupMessages'
import findFirstUnreadMessageId from '~/helpers/chat/findFirstUnreadMessageId'
import getOperatorName from '~/helpers/getOperatorName'
import getChatTitle from '~/helpers/getChatTitle'
import isMessageReadable from '~/helpers/chat/isMessageReadable'
import isOwnMessage from '~/helpers/chat/isOwnMessage'

export const useChatStore = defineStore('chatStore', () => {
    // Внутрішні змінні
    const initiated = useState<boolean>('chatStoreInitiated', () => false)
    const userStore = useUserStore()
    const api = useApi()
    const bus = useEventBus()
    const lang = useLang()
    const notify = useNotify()
    const tab = useTabActivity()
    const windowSize = useWindowSize()
    const { currentRoute } = useRouter()
    const chatRouteChangedHook = createEventHook<number>()
    const chatTransferCanceledHook = createEventHook<number>()
    const chatTypingHandlers = new Map<chatTypes.Chat['id'], TypingHandler>()
    const refreshChatsOnTabActive = ref<boolean>(false)
    const transferChatTimers: Record<string, ReturnType<typeof useTimer>> = {}
    const messageSendingQueue = new MessageSendingQueue()

    // Змінні для експорту
    const chats = ref<chatTypes.Chat[]>([])
    const quickAnswerTags = shallowRef<QuickAnswerTag[]>([])
    const quickAnswers = shallowRef<QuickAnswer[]>([])
    const knowledgeBaseItems = shallowRef<KBItem[]>([])
    const operators = shallowRef<Operator[]>([])
    const gifs = shallowRef<chatTypes.ChatGif[]>([])
    const communicationChannels = shallowRef<CChannel[]>([])
    const currentKind = ref<ChatKindEnum>(currentRoute.value.params.kind as ChatKindEnum)
    const currentCid = ref<number>(+currentRoute.value.params.cid)
    const chatListRefreshKey = ref<number>(0)
    const cidPageRefreshKey = ref<number>(0)
    const chatTypingContexts = ref<Record<chatTypes.Chat['id'], chatTypes.ChatTypingContext>>({})
    const archiveChatListFilters = ref<chatTypes.ChatArchiveFilters>({
        dateFrom: undefined,
        dateTo: undefined,
        status: ChatStatusEnum.Closed,
        channels: [],
        operators: []
    })
    const archiveChatsFiltering = ref<boolean>(false)
    const archiveChatsLoading = ref<boolean>(false)
    const archiveChatsPage = ref<number>(1)
    const noMoreArchiveChats = ref<boolean>(false)

    tab.onTabActive(() => {
        if (refreshChatsOnTabActive.value) {
            refreshChats()
        }
    })

    const newChatList = computed<chatTypes.Chat[]>(() => {
        return chats.value.filter(v => v.status === ChatStatusEnum.New)
    })

    const myChatList = computed<chatTypes.Chat[]>(() => {
        return chats.value.filter(v => v.status === ChatStatusEnum.Active)
    })

    const archiveChatList = computed<chatTypes.Chat[]>(() => {
        return chats.value.filter(v => CHAT_ARCHIVE_STATUSES.includes(v.status))
    })

    const currentChatList = computed<chatTypes.Chat[]>(() => {
        switch (currentKind.value) {
            case ChatKindEnum.New:
                return newChatList.value
            case ChatKindEnum.My:
                return myChatList.value
            case ChatKindEnum.Archive:
                return archiveChatList.value
            default:
                return []
        }
    })

    const currentChatSortedList = computed<chatTypes.Chat[]>(() => {
        return currentChatList.value.sort((a, b) => {
            const lastMessageTimeA = a.messages.length ? a.messages.at(-1).created_at : 0
            const lastMessageTimeB = b.messages.length ? b.messages.at(-1).created_at : 0

            return (lastMessageTimeB - lastMessageTimeA) || (a.created_at - b.created_at)
        })
    })

    // TODO оптимізувати, на стороні серверу не кешується і викликається десятки разів https://github.com/nuxt/nuxt/issues/2447
    const chatsById = computed<Record<number, chatTypes.Chat>>(() => {
        return chats.value.reduce((result, chat) => {
            result[chat.id] = chat

            return result
        }, {})
    })

    const chatIds = computed<number[]>(() => chats.value.map(v => v.id))

    // TODO обробити кейс, якщо у урл буде обрано чат, який не буде на першій сторінці пагінації при оновленні сторінки
    const currentChat = computed<chatTypes.Chat | undefined>(() => {
        if (!currentCid.value) {
            return
        }

        return chatsById.value[currentCid.value]
    })

    const isCurrentChatNew = computed<boolean>(() => {
        return currentChat.value?.status === ChatStatusEnum.New
    })

    const isCurrentChatClosed = computed<boolean>(() => {
        return CHAT_ARCHIVE_STATUSES.includes(currentChat.value?.status)
    })

    const isCurrentChatInTransferProcess = computed<boolean>(() => {
        return !!currentChat.value?.chatTransfer
    })

    const operatorOptions = computed<FormSelectOption[]>(() => {
        const operatorOptions: FormSelectOption[] = []

        for (const index in operators.value) {
            const value = operators.value[index]

            if (
                (value.id === userStore.currentOperator.id)
                || !value.enabled
                || value.invited_at
            ) {
                continue
            }

            operatorOptions.push({
                _extra: {
                    searchValue: (value.user.name || '') + value.user.email,
                    ...value.user
                },
                value: value.id,
                text: value.user.email
            })
        }

        return operatorOptions
    })

    const numberOfUnreadMessagesInNewChats = computed<number>(() => {
        return newChatList.value.length
    })

    const numberOfUnreadMessagesInMyChats = computed<number>(() => {
        return myChatList.value.reduce((count, chat) => {
            if (chat.numberOfUnreadMessages) {
                count++
            }

            return count
        }, 0)
    })

    const isThereChatWithUnreadMessages = computed<boolean>(() => {
        return !!(
            numberOfUnreadMessagesInNewChats.value
            + numberOfUnreadMessagesInMyChats.value
        )
    })

    const isThereChatWithUnreadMessagesInOtherProjectsByProjectId = computed<Record<string, boolean>>(() => {
        return userStore.handshakeUnreadInOtherProjects.reduce((result, value) => {
            if (!result[value.project_id]) {
                result[value.project_id] = false
            }

            if (value.unread) {
                result[value.project_id] = true
            }

            return result
        }, {})
    })

    const isChatRoute = (route: RouteLocationNamedRaw | RouteLocationNormalizedLoaded): boolean => {
        return !!route?.name && String(route.name).startsWith('p-pid-chat')
    }

    const useTypingHandler = (id: chatTypes.Chat['id']): TypingHandler => {
        if (!chatTypingHandlers.has(id)) {
            chatTypingContexts.value[id] = { text: '', typing: false }
            chatTypingHandlers.set(id, new TypingHandler(chatTypingContexts.value[id]))
        }

        return chatTypingHandlers.get(id)
    }

    const removeTypingHandler = (id: chatTypes.Chat['id']): void => {
        chatTypingHandlers.delete(id)
        delete chatTypingContexts.value[id]
    }

    const handleHookNotification = (notification: Notification | undefined): void => {
        // Немає дозволу браузера
        if (!notification) {
            return
        }

        const offChatRouteChangedHook = chatRouteChangedHook.on((cid) => {
            if (cid === notification.data.chatId) {
                cleanUp()
            }
        })

        const offChatTransferCanceledHook = chatTransferCanceledHook.on((cid) => {
            if (cid === notification.data.chatId) {
                cleanUp()
            }
        })

        const cleanUp = () => {
            notification.close()

            offChatRouteChangedHook()
            offChatTransferCanceledHook()
        }

        notification.onclick = () => {
            cleanUp()

            window.focus()

            notification.close()

            const chat = chatsById.value[notification.data.chatId]

            if (!chat) {
                return
            }

            navigateTo({
                name: 'p-pid-chat-kind-cid',
                params: {
                    kind: getChatKind(chat),
                    cid: notification.data.chatId
                }
            })
        }
    }

    const startChatConnection = (chat: chatTypes.Chat): void => {
        const chatChannel = broadcastManager.addChatChannel(chat.id)
        const chatClientChannel = broadcastManager.addChatClientChannel(chat.id)

        if (!chatChannel.channel.isSubscribed) {
            chatChannel.channel.subscribe()
        }

        if (!chatClientChannel.channel.isSubscribed) {
            chatClientChannel.channel.subscribe()
        }

        if (chatChannel.created) {
            chatChannel.events.onNewMessage((message: chatTypes.ChatMessage, done) => {
                const chatBodyEl = document.querySelector('[data-chat-conversation-body]')

                chat.numberOfUnreadMessages++

                if (!chatBodyEl) {
                    insertMessagesIntoChat(chat, [ message ], { position: 'after' })
                } else {
                    const { scrollHeight, scrollTop, clientHeight } = chatBodyEl
                    const scrollBottomBefore = scrollHeight - scrollTop - clientHeight

                    insertMessagesIntoChat(chat, [ message ], { position: 'after' })

                    if (scrollBottomBefore < CHAT_SCROLL_BOTTOM_THRESHOLD) {
                        nextTick(() => bus.emit(BUS_EVENT_CHAT_SCROLL_BODY))
                    }
                }

                done(handleHookNotification)

                chatListRefreshKey.value++
            })

            chatChannel.events.onMessageUpdate((message: chatTypes.ChatMessage, done) => {
                updateChatMessage(currentChat.value, message, message)

                done()
            })

            chatChannel.events.onMessageDelete((message: chatTypes.ChatMessage, done) => {
                removeChatMessage(currentChat.value, message)

                done()

                if (!message.viewed_at) {
                    chat.numberOfUnreadMessages--
                }

                chatListRefreshKey.value++
            })

            chatChannel.events.onMessageRead((message: chatTypes.ChatMessage, done) => {
                updateChatMessage(chat, message, message)

                done()
            })
        }

        if (chatClientChannel.created) {
            chatClientChannel.events.onTyping(({ typing, text }, done) => {
                const typingHandler = useTypingHandler(chat.id)

                if (typing) {
                    typingHandler.write(text)
                } else {
                    typingHandler.stop('visitor')
                }

                done()
            })
        }
    }

    const addChats = (items: chatTypes.Chat[]): void => {
        for (const item of items) {
            if (chatIds.value.includes(item.id)) {
                return
            }

            // Виправлення проблеми реактивності
            // Бо після додавання нового чату далі обʼєкт втрачає посилання та не реагує на зміни (наприклад додавання повідомлення у новий чат)
            const itemRef = ref(item)

            setChatExtraData(itemRef.value)

            chats.value.push(itemRef.value)

            if (process.client) {
                startChatConnection(itemRef.value)
            }
        }
    }

    const removeChats = async (items: chatTypes.Chat[], _ensureCurrentCid = true): Promise<void> => {
        if (!items.length) {
            return
        }

        const idsToRemove = new Set(
            items.map(({ id }) => {
                broadcastManager.removeChatChannel(id, true)
                broadcastManager.removeChatClientChannel(id, true)

                removeTypingHandler(id)

                return id
            })
        )

        chats.value = chats.value.filter(({ id }) => !idsToRemove.has(id))

        if (_ensureCurrentCid) {
            await ensureCurrentCid()
        }
    }

    const updateChat = (id: chatTypes.Chat['id'], chat: chatTypes.Chat, preserveMessages = false): void => {
        const existingChat = chatsById.value[id]

        if (preserveMessages) {
            chat.messages = existingChat.messages
        }

        setChatExtraData(chat)

        Object.assign(existingChat, chat)
    }

    const isChatListEmpty = (kind: ChatKindEnum): boolean => {
        switch (kind) {
            case ChatKindEnum.New:
                return !newChatList.value.length
            case ChatKindEnum.My:
                return !myChatList.value.length
            case ChatKindEnum.Archive:
                return !archiveChatList.value.length
        }
    }

    const buildChatListQuery = (kind: ChatKindEnum) => {
        if (kind !== ChatKindEnum.Archive) {
            return
        }

        const filter: Record<string, any> = {
            status: archiveChatListFilters.value.status === ChatStatusEnum.Closed
                ? [
                    ChatStatusEnum.Closed,
                    ChatStatusEnum.Missed,
                    ChatStatusEnum.Rejected
                ]
                : [
                    ChatStatusEnum.Missed,
                    ChatStatusEnum.Rejected
                ],
            createdAt: {}
        }

        if (archiveChatListFilters.value.dateFrom) {
            filter.createdAt['>='] = dateUtil
                .fromMillis(archiveChatListFilters.value.dateFrom)
                .startOf('day')
                .toFormat(DATE_DEFAULT_DATETIME_FORMAT)
        }

        if (archiveChatListFilters.value.dateTo) {
            filter.createdAt['<='] = dateUtil
                .fromMillis(archiveChatListFilters.value.dateTo)
                .endOf('day')
                .toFormat(DATE_DEFAULT_DATETIME_FORMAT)
        }

        if (archiveChatListFilters.value.channels.length) {
            filter.channel_type = archiveChatListFilters.value.channels
        }

        if (archiveChatListFilters.value.operators.length) {
            filter.operator_id = archiveChatListFilters.value.operators
        }

        return { filter }
    }

    const getChats = async (kinds: ChatKindEnum[], onlyEmpty = false): Promise<chatTypes.Chat[]> => {
        const responses = await Promise.all(
            kinds
                .filter(onlyEmpty ? isChatListEmpty : Boolean)
                .map((kind) => {
                    return api.chat.chats({
                        page: 1,
                        perPage: (kind === ChatKindEnum.Archive) ? CHAT_ARCHIVE_LIST_PER_PAGE : APP_MAX_PER_PAGE,
                        kind,
                        query: buildChatListQuery(kind)
                    })
                })
        )

        const items = []

        responses.forEach(({ data, error }) => {
            if (error.value) {
                throw error.value
            }

            items.push(...data.value.items)
        })

        return items
    }

    const fillChats = async (): Promise<void> => {
        addChats(
            await getChats([
                ChatKindEnum.New,
                ChatKindEnum.My,
                ChatKindEnum.Archive
            ], true)
        )
    }

    const fillQuickAnswers = async (refresh = false): Promise<void> => {
        if (!refresh && quickAnswers.value.length) {
            return
        }

        const [
            { data: tags, error: tagError },
            { data: answers, error: answerError }
        ] = await Promise.all([
            api.quickAnswer.tagList(),
            api.quickAnswer.all()
        ])

        if (tagError.value || answerError.value) {
            throw tagError.value || answerError.value
        }

        quickAnswerTags.value = tags.value
        quickAnswers.value = answers.value.items
    }

    const fillKnowledgeBase = async (refresh = false): Promise<void> => {
        if (!refresh && knowledgeBaseItems.value.length) {
            return
        }

        const languageStore = useLanguageStore()

        const { data, error } = await api.knowledgeBase.tree({
            onlyPublished: 1,
            contentLanguage: languageStore.language
        })

        if (error.value) {
            throw error.value
        }

        knowledgeBaseItems.value = data.value.children
    }

    const fillOperators = async (refresh = false): Promise<void> => {
        if (!refresh && operators.value.length) {
            return
        }

        const { data, error } = await api.operator.all({ perPage: APP_MAX_PER_PAGE })

        if (error.value) {
            throw error.value
        }

        operators.value = data.value.items
    }

    const fillGifs = async (): Promise<void> => {
        if (gifs.value.length) {
            return
        }

        const { data, error } = await api.chat.gifFavorite()

        if (error.value) {
            throw error.value
        }

        gifs.value = data.value
    }

    const fillCommunicationChannels = async (refresh = false): Promise<void> => {
        if (!refresh && communicationChannels.value.length) {
            return
        }

        const { data, error } = await api.communicationChannel.all()

        if (error.value) {
            throw error.value
        }

        communicationChannels.value = data.value.items
    }

    const ensureCurrentCid = async (
        preferredKind: ChatKindEnum = currentKind.value,
        forcedKind = false,
        disabled = windowSize.maxTablet.value
    ): Promise<void | ReturnType<typeof navigateTo>> => {
        if (disabled) {
            return
        }

        if (!isChatRoute({ name: currentRoute.value.name }) || currentChat.value) {
            return
        }

        await nextTick() // Для коректної роботи (на випадок якщо цеей метод викликається після зміни чатів)

        const chatLists = forcedKind
            ? [
                [ preferredKind, getChatListByKind(preferredKind) ]
            ]
            : [
                [ ChatKindEnum.New, newChatList.value ],
                [ ChatKindEnum.My, myChatList.value ]
            ]

        chatLists.sort((a: any) => a[0] === preferredKind ? -1 : 1)

        for (const [ kind, list ] of chatLists) {
            if (!list.length) {
                continue
            }

            return navigateTo({
                name: 'p-pid-chat-kind-cid',
                params: {
                    kind: kind as ChatKindEnum,
                    cid: (list[0] as chatTypes.Chat).id
                }
            }, { replace: true })
        }

        // Якщо чатів немає, але чат присутній в url
        // Чи потрібна інша вкладка списку чатів
        if (currentCid.value || (currentKind.value !== preferredKind)) {
            return navigateTo({
                name: 'p-pid-chat-kind',
                params: { kind: preferredKind }
            }, { replace: true })
        }
    }

    const removeCurrentCid = (): ReturnType<typeof navigateTo> => {
        return navigateTo({
            name: 'p-pid-chat-kind',
            params: { kind: currentKind.value }
        })
    }

    const sendMessage = (
        type: ChatMessageTypeEnum,
        value: {
            text?: string
            file?: File
            gif?: chatTypes.ChatGif
            article?: KBArticle
        },
        options: {
            isNote?: boolean
            callbackBeforeInsert?: Function
        } = {}
    ): void => {
        if (!value) {
            return
        }

        if (value.text) {
            value.text = isString(value.text)
                ? value.text.trim()
                : String(value.text)
        }

        switch (type) {
            case ChatMessageTypeEnum.Image:
                if (!value.file) {
                    return
                }
                break
            case ChatMessageTypeEnum.Gif:
                if (!value.gif) {
                    return
                }
                break
            case ChatMessageTypeEnum.Article:
                if (!value.article) {
                    return
                }
                break
            default:
                if (!value.text) {
                    return
                }
        }

        const _currentChat = currentChat.value
        const sender = userStore.currentOperator
        const tempId = stringUtil.generateHash()

        const newMessage: chatTypes.ChatMessage = {
            sendingError: false,

            _id: tempId,
            native_id: undefined,
            is_note: Number(options.isNote),
            channel_id: _currentChat.channel_id,
            chat_id: _currentChat.id,
            sender_id: sender.id,
            sender_type: ChatMemberUserTypeEnum.Operator,
            senderEntity: sender,
            type,
            text: value.text,
            image_url: '',
            photo: undefined,
            article: undefined,
            edited_at: undefined,
            viewed_at: undefined,
            created_at: dateUtil.local().toUnixInteger(),
            updated_at: undefined
        }

        const formData = new FormData()

        formData.append('sender_id', String(sender.id))

        if (options.isNote) {
            formData.append('is_note', '1')
        }

        let methodName: string

        switch (type) {
            case ChatMessageTypeEnum.Image:
                newMessage.photo = { temp: URL.createObjectURL(value.file) }

                value.text && formData.append('text', value.text)
                formData.append('file', value.file)

                methodName = 'chatMessageSendImage'
                break
            case ChatMessageTypeEnum.Gif:
                newMessage.image_url = value.gif.url

                formData.append('gif_id', value.gif.gif_id)

                methodName = 'chatMessageSendGif'
                break
            case ChatMessageTypeEnum.Article:
                newMessage.article = value.article

                formData.append('kb_node_id', value.article._id)

                methodName = 'chatMessageSendArticle'
                break
            default:
                formData.append('text', value.text)

                methodName = 'chatMessageSendText'
        }

        options.callbackBeforeInsert?.()

        insertMessagesIntoChat(_currentChat, [ newMessage ], { position: 'after' })

        nextTick(() => bus.emit(BUS_EVENT_CHAT_SCROLL_BODY))

        chatListRefreshKey.value++

        messageSendingQueue.enqueue(async () => {
            const { data, error } = await api.chat[methodName]({
                chatId: _currentChat.id,
                data: formData
            })

            const updatedMessage = error.value
                ? { ...newMessage, sendingError: true }
                : { ...newMessage, ...data.value }

            const messageIndex = _currentChat.messages.findIndex(v => v._id === newMessage._id)

            if ((messageIndex + 1) < _currentChat.messages.length) {
                // Перенесення повідомлення вниз, якщо воно було завантажено пізніше попередніх (наприклад, медіафайл)
                removeChatMessage(_currentChat, newMessage)

                insertMessagesIntoChat(_currentChat, [ updatedMessage ], { position: 'after' })
            } else {
                Object.assign(_currentChat.messages[messageIndex], updatedMessage)
            }

            appCache.delete(CACHE_CHAT_MESSAGES_PREFIX + tempId)
        })
    }

    const sendTyping = (chatId: chatTypes.Chat['id'], payload: {
        typing: boolean
    }): void => {
        broadcastManager?.sendChatClientChannelMessage(chatId, WSClientChannelEventEnum.Typing, payload)
    }

    const insertGroupedMessagesIntoChat = (
        chat: chatTypes.Chat,
        groupedMessages: chatTypes.ChatMessageDateGroup[],
        position: 'before' | 'after' = 'before'
    ): void => {
        if (!groupedMessages.length) {
            return
        }

        const existingIndex = position === 'before' ? 0 : -1
        const insertMethod = position === 'before' ? 'unshift' : 'push'

        const receivedIndex = position === 'before' ? -1 : 0
        const extractMethod = position === 'before' ? 'pop' : 'shift'

        const currentExistingDateGroup = chat.groupedMessages.at(existingIndex)

        if (
            currentExistingDateGroup
            && (currentExistingDateGroup.dayStartAt === groupedMessages.at(receivedIndex)?.dayStartAt)
        ) {
            const currentExistingSenderGroup = currentExistingDateGroup.senderGroups.at(existingIndex)
            const extractedReceivedDateGroup = groupedMessages[extractMethod]()

            if (
                currentExistingSenderGroup.senderKey
                ===
                extractedReceivedDateGroup.senderGroups.at(receivedIndex).senderKey
            ) {
                const extractedSenderGroup = extractedReceivedDateGroup.senderGroups[extractMethod]()

                currentExistingSenderGroup.messages[insertMethod](...extractedSenderGroup.messages)
            }

            currentExistingDateGroup.senderGroups[insertMethod](...extractedReceivedDateGroup.senderGroups)
        }

        chat.groupedMessages[insertMethod](...groupedMessages)
    }

    const insertMessagesIntoChat = (
        chat: chatTypes.Chat,
        messages: chatTypes.ChatMessage[],
        options: {
            position?: 'before' | 'after'
            firstUnreadId?: string
            withGrouped?: boolean
        } = {}
    ): void => {
        const {
            position = 'before',
            firstUnreadId = '',
            withGrouped = true
        } = options

        chat.messages[position === 'before' ? 'unshift' : 'push'](...messages)

        if (withGrouped) {
            insertGroupedMessagesIntoChat(chat, groupMessages(lang, messages, firstUnreadId), position)
        }
    }

    const setChatExtraData = (chat: chatTypes.Chat): chatTypes.Chat => {
        const unreadItemIndex = userStore.handshakeUnreadInCurrentProject.findIndex(v => v.chat_id === chat.id)

        if (unreadItemIndex === -1) {
            // Не 0, бо можливо це новий чат отриманий по сокетам
            chat.numberOfUnreadMessages = chat.messages.filter((message) => {
                return isMessageReadable(message) && !isOwnMessage(message, userStore.currentOperator)
            }).length
        } else {
            chat.numberOfUnreadMessages = userStore.handshakeUnreadInCurrentProject.splice(unreadItemIndex, 1)[0].unread
        }

        chat.noMoreMessages = false

        chat.groupedMessages = groupMessages(
            lang,
            chat.messages,
            findFirstUnreadMessageId(chat.messages, userStore.currentOperator, chat.noMoreMessages)
        )

        useTypingHandler(chat.id)

        return chat
    }

    const updateChatMessage = (
        chat: chatTypes.Chat,
        message: chatTypes.ChatMessage,
        updatedMessage: chatTypes.ChatMessage
    ): void => {
        const messageIndex = chat.messages.findIndex(v => v._id === message._id)

        Object.assign(chat.messages[messageIndex], updatedMessage)

        ;(() => {
            for (const dateGroupIndex in chat.groupedMessages) {
                const dateGroup = chat.groupedMessages[dateGroupIndex]

                for (const senderGroupIndex in dateGroup.senderGroups) {
                    const senderGroup = dateGroup.senderGroups[senderGroupIndex]

                    const messageIndex = senderGroup.messages.findIndex(v => v._id === message._id)

                    if (messageIndex !== -1) {
                        Object.assign(senderGroup.messages[messageIndex], updatedMessage)

                        return
                    }
                }
            }
        })()

        appCache.delete(CACHE_CHAT_MESSAGES_PREFIX + message._id)
    }

    const removeChatMessage = (chat: chatTypes.Chat, message: chatTypes.ChatMessage): void => {
        const messageIndex = chat.messages.findIndex(v => v._id === message._id)

        chat.messages.splice(messageIndex, 1)

        ;(() => {
            for (const dateGroupIndex in chat.groupedMessages) {
                const dateGroup = chat.groupedMessages[dateGroupIndex]

                for (const senderGroupIndex in dateGroup.senderGroups) {
                    const senderGroup = dateGroup.senderGroups[senderGroupIndex]

                    const messageIndex = senderGroup.messages.findIndex(v => v._id === message._id)

                    if (messageIndex !== -1) {
                        senderGroup.messages.splice(messageIndex, 1)

                        if (!senderGroup.messages.length) {
                            dateGroup.senderGroups.splice(+senderGroupIndex, 1)
                        }

                        if (!dateGroup.senderGroups.length) {
                            chat.groupedMessages.splice(+dateGroupIndex, 1)
                        }

                        return
                    }
                }
            }
        })()

        appCache.delete(CACHE_CHAT_MESSAGES_PREFIX + message._id)
    }

    const submitEditableMessage = async (): Promise<void> => {
        const chat = currentChat.value

        if (!chat.editableMessage || chat.editableMessage.pendingForUpdate) {
            return
        }

        chat.editableMessage.pendingForUpdate = true

        const { data, error } = await api.chat.chatMessageUpdate({
            chatId: chat.id,
            messageId: chat.editableMessage._id,
            data: {
                text: chat.editableMessage.editableText
            }
        })

        if (error.value) {
            chat.editableMessage.pendingForUpdate = false

            return
        }

        updateChatMessage(chat, chat.editableMessage, data.value)

        chat.editableMessage = undefined
    }

    const refreshArchivedChats = async (): Promise<void> => {
        await removeChats(chats.value.filter(v => CHAT_ARCHIVE_STATUSES.includes(v.status)), false)

        archiveChatsPage.value = 1
        noMoreArchiveChats.value = false

        addChats(await getChats([ ChatKindEnum.Archive ]))

        await ensureCurrentCid(ChatKindEnum.Archive, true)
    }

    const getChatKind = (chat: chatTypes.Chat): ChatKindEnum => ({
        [ChatStatusEnum.New]: ChatKindEnum.New,
        [ChatStatusEnum.Active]: ChatKindEnum.My,
        [ChatStatusEnum.Closed]: ChatKindEnum.Archive,
        [ChatStatusEnum.Missed]: ChatKindEnum.Archive,
        [ChatStatusEnum.Rejected]: ChatKindEnum.Archive
    }[chat.status])

    const getChatListByKind = (kind: ChatKindEnum): chatTypes.Chat[] => ({
        [ChatKindEnum.New]: newChatList.value,
        [ChatKindEnum.My]: myChatList.value,
        [ChatKindEnum.Archive]: archiveChatList.value
    }[kind])

    const init = async (): Promise<void> => {
        if (initiated.value) {
            return
        }

        initiated.value = true

        await Promise.all([
            fillCommunicationChannels(),
            fillOperators(),
            fillChats()
        ])

        archiveChatListFilters.value.operators.push(userStore.currentOperator.id)
    }

    const refreshChats = async (): Promise<void> => {
        if (!tab.isTabActive.value) {
            refreshChatsOnTabActive.value = true

            // Відновлення підписки на старі чати
            chats.value.forEach(startChatConnection)

            return
        }

        refreshChatsOnTabActive.value = false
        archiveChatsPage.value = 1
        noMoreArchiveChats.value = false

        const newChats = await getChats([
            ChatKindEnum.New,
            ChatKindEnum.My,
            ChatKindEnum.Archive
        ])

        const newChatIds = newChats.map(v => v.id)

        await removeChats(chats.value.filter(v => !newChatIds.includes(v.id)), false)

        newChats
            .forEach((chat) => {
                const existingChat = chatsById.value[chat.id]

                if (existingChat) {
                    existingChat.messages = chat.messages

                    removeTypingHandler(existingChat.id)
                    setChatExtraData(existingChat)
                    startChatConnection(existingChat)
                } else {
                    addChats([ chat ])
                }
            })

        await ensureCurrentCid()

        chatListRefreshKey.value++
        cidPageRefreshKey.value++
    }

    const clearTransferChatTimers = (chat: chatTypes.Chat): void => {
        if (!transferChatTimers[chat.id]) {
            return
        }

        transferChatTimers[chat.id].stop()

        delete transferChatTimers[chat.id]
    }

    const handleTransferredChats = (): void => {
        if (!process.client) {
            return
        }

        for (const chat of chats.value) {
            if (chat.chatTransfer) {
                clearTransferChatTimers(chat)

                const milliseconds = Math.floor(
                    dateUtil
                        .rawFromSQL(chat.chatTransfer.expired_at, { zone: 'utc' })
                        .diffNow('millisecond')
                        .milliseconds
                )

                transferChatTimers[chat.id] = useTimer(() => {
                    delete transferChatTimers[chat.id]

                    const wantedChat = chatsById.value[chat.id]

                    if (!wantedChat?.chatTransfer) { // Було видалено чи прийнято
                        return
                    }

                    if (wantedChat.chatTransfer.fromOperator.id === userStore.currentOperator.id) {
                        wantedChat.chatTransfer = undefined
                    } else {
                        removeChats([ wantedChat ])
                    }
                }, milliseconds, { immediate: true })
            }
        }
    }

    watch(currentRoute, (route) => {
        if (!isChatRoute(route)) {
            return
        }

        const { kind, cid } = route.params

        currentKind.value = kind as ChatKindEnum
        currentCid.value = +cid

        chatRouteChangedHook.trigger(currentCid.value)
    })

    /* WS */

    // Сокети доступні тільки на клієнті (також на сервері була б помилка через використання composable методів у BroadcastManager при ініціалізації)
    let broadcastManager: BroadcastManager | undefined

    if (process.client) {
        broadcastManager = new BroadcastManager()

        broadcastManager.silentMode.value = !userStore.isOperatorOnline

        watch(() => userStore.isOperatorOnline, (value) => {
            broadcastManager.silentMode.value = !value
        })

        const {
            channel: projectChatsChannel,
            events: {
                onNewChat,
                onChatAccepted,
                onNewTransferredChat,
                onChatRejected,
                onChatFinished
            }
        } = broadcastManager.addProjectChatsChannel(userStore.currentProject.id)

        onNewChat((chat: chatTypes.Chat, done) => {
            addChats([ chat ])

            done(handleHookNotification)
        })

        onNewTransferredChat((chat: chatTypes.Chat, done) => {
            addChats([ chat ])

            nextTick(handleTransferredChats)

            const notifyId = 'chat-transfer:' + chat.id

            const cleanUp = (): void => {
                notify.remove(notifyId)

                offChatRouteChangedHook()
                offChatTransferCanceledHook()
            }

            const offChatRouteChangedHook = chatRouteChangedHook.on((cid) => {
                if (cid === chat.id) {
                    cleanUp()
                }
            })

            const offChatTransferCanceledHook = chatTransferCanceledHook.on((cid) => {
                if (cid === chat.id) {
                    cleanUp()
                }
            })

            const milliseconds = Math.floor(
                dateUtil
                    .rawFromSQL(chat.chatTransfer.expired_at, { zone: 'utc' })
                    .diffNow('millisecond')
                    .milliseconds
            )

            notify.push({
                id: notifyId,
                type: 'custom',
                text: lang.t('chat-transfer-notify', getOperatorName(chat.chatTransfer.toOperator), getChatTitle(chat)),
                timeout: milliseconds,
                pauseOnHover: false,
                closeOnClick: false,
                customTextColor: '#fff',
                customBgColor: '#1a1a1a',
                customIconComponentName: 'AppIconOperatorSwitch',
                actionText: useLang().t('view'),
                actionTextInactiveColor: '#aaadb8',
                onAction() {
                    cleanUp()

                    if (chatsById.value[chat.id]) {
                        navigateTo({
                            name: 'p-pid-chat-kind-cid',
                            params: {
                                kind: getChatKind(chat),
                                cid: chat.id
                            }
                        })
                    }
                },
                onClose: cleanUp
            })

            done(handleHookNotification)
        })

        onChatAccepted((chat: chatTypes.Chat, done) => {
            removeChats([ chat ])

            done()
        })

        onChatRejected(async (chat: chatTypes.Chat, done) => {
            chatTransferCanceledHook.trigger(chat.id)

            // Відмова від нового чату
            if (chat.status === ChatStatusEnum.Rejected) {
                updateChat(chat.id, chat)

                if (currentCid.value === chat.id) {
                    await removeCurrentCid()
                    await ensureCurrentCid(ChatKindEnum.New)
                }

                return done()
            }

            // Відмова від передачі отримувачем
            if (chat.operator_id === userStore.currentOperator.id) {
                updateChat(chat.id, chat)

                cidPageRefreshKey.value++

                return done()
            }

            /* Скасування передачі ініціатором */

            removeChats([ chat ])

            if (currentCid.value === chat.id) {
                await removeCurrentCid()
                await ensureCurrentCid(ChatKindEnum.New)
            }

            done()
        })

        onChatFinished((chat: chatTypes.Chat, done) => {
            addChats([ chat ])

            done()
        })

        broadcastManager.broadcaster.onConnected(() => {
            projectChatsChannel.subscribe()

            if (broadcastManager.broadcaster.wasDisconnected.value) {
                refreshChats()
            } else {
                // Початкова ініціалізація
                chats.value.forEach(startChatConnection)
            }
        })

        broadcastManager.broadcaster.onDisconnected(() => {
            projectChatsChannel.unsubscribe()

            chatIds.value.forEach((id) => {
                broadcastManager.getChatChannel(id)?.unsubscribe()
                broadcastManager.getChatClientChannel(id)?.unsubscribe()
            })
        })
    }

    return {
        chats,
        quickAnswerTags,
        quickAnswers,
        knowledgeBaseItems,
        operators,
        gifs,
        communicationChannels,
        currentKind,
        currentCid,
        chatListRefreshKey,
        cidPageRefreshKey,
        chatTypingContexts,
        archiveChatListFilters,
        archiveChatsFiltering,
        archiveChatsLoading,
        archiveChatsPage,
        noMoreArchiveChats,

        newChatList,
        myChatList,
        archiveChatList,
        currentChatList,
        currentChatSortedList,
        chatIds,
        currentChat,
        isCurrentChatNew,
        isCurrentChatClosed,
        isCurrentChatInTransferProcess,
        operatorOptions,
        numberOfUnreadMessagesInNewChats,
        numberOfUnreadMessagesInMyChats,
        isThereChatWithUnreadMessages,
        isThereChatWithUnreadMessagesInOtherProjectsByProjectId,

        fillQuickAnswers,
        fillKnowledgeBase,
        fillGifs,
        ensureCurrentCid,
        removeCurrentCid,
        sendMessage,
        sendTyping,
        insertMessagesIntoChat,
        refreshArchivedChats,
        getChatKind,
        init,
        buildChatListQuery,
        addChats,
        removeChats,
        updateChat,
        submitEditableMessage,
        updateChatMessage,
        removeChatMessage,
        handleTransferredChats
    }
})
