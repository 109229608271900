import type { ChatMessage, ChatMessageSender } from '~/ts/types/chat'
import { ChatMessageTypeEnum } from '~/ts/enums/chat'
import isOwnMessage from '~/helpers/chat/isOwnMessage'

export default (messages: ChatMessage[], sender: ChatMessageSender, allowFirstItem: boolean): string | undefined => {
    let firstUnreadId: string

    for (const index in messages) {
        const message = messages[index]

        if (
            !message.viewed_at
            && (message.type !== ChatMessageTypeEnum.Log)
            && !isOwnMessage(message, sender)
        ) {
            // Якщо непрочитане повідомлення не перше в списку завантажених (незавантажені раніше нього також можуть бути непрочитані)
            if (+index || allowFirstItem) {
                firstUnreadId = message._id
            }

            break
        }
    }

    return firstUnreadId
}
