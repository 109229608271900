import type * as userTypes from '~/ts/types/user'

export default {
    one({ id, expand }: userTypes.UserOne) {
        return useAppFetch<userTypes.User>('/user/{userId}', {
            params: {
                id,
                expand: getFilledKeysString(expand)
            }
        })
    },
    update({ data }: userTypes.UserUpdate) {
        return useAppFetch<userTypes.User>('/user/{userId}', {
            method: 'put',
            body: data
        })
    },
    updatePhoto({ data }: userTypes.UserUpdatePhoto) {
        return useAppFetch<{ id: any, file: userTypes.User['photo'] }>('/user/{userId}/photo', {
            method: 'post',
            body: data
        })
    },
    deletePhoto() {
        return useAppFetch('/user/{userId}/photo', {
            method: 'delete'
        })
    },
    emailChangeRequest({ data }: userTypes.UserEmailChangeRequest) {
        return useAppFetch<userTypes.User['changeEmailRequest']>('/user/{userId}/change-email-request', {
            method: 'post',
            body: data
        })
    },
    emailChangeRequestRepeat({ requestId }: userTypes.UserEmailChangeRequestRepeat) {
        return useAppFetch(`/user/{userId}/change-email-request/${ requestId }`, {
            method: 'post'
        })
    },
    emailChangeRequestDelete({ requestId }: userTypes.UserEmailChangeRequestDelete) {
        return useAppFetch(`/user/{userId}/change-email-request/${ requestId }`, {
            method: 'delete'
        })
    },
    passwordChangeRequest() {
        return useAppFetch('/user/{userId}/change-password', {
            method: 'post'
        })
    }
}
