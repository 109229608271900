import { default as _91token_93yVY2RBBQ6pMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/change-password/[token].vue?macro=true";
import { default as _91token_93_46clientctIuYPcWVnMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/confirm/[token].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/codebuild/output/src1727701116/src/spilky-client/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91token_93uuapwkHNVwMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/create-password/[token].vue?macro=true";
import { default as _91token_93_46clientxetYjGe2Z2Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/invite/[token].client.vue?macro=true";
import { default as google_46clientCmOl2UWYQsMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/oauth/google.client.vue?macro=true";
import { default as _91token_93n7FvQdaGfIMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/reset-password/[token].vue?macro=true";
import { default as requestqxBr7CUOwgMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/reset-password/request.vue?macro=true";
import { default as sign_45inTNMqkf4BhoMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-in.vue?macro=true";
import { default as indexxK9ebrXpbNMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/index.vue?macro=true";
import { default as _1HoKEKdpIV7Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/step/1.vue?macro=true";
import { default as _2c2Rv74o3xIMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/step/2.vue?macro=true";
import { default as _393xamtg2m1Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/step/3.vue?macro=true";
import { default as _4wOAUX529NuMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/step/4.vue?macro=true";
import { default as stepPUnzs1bLMTMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/step.vue?macro=true";
import { default as widget_45simulator_45_91token_93_46clientfwf9VAq7HPMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/external/widget-simulator-[token].client.vue?macro=true";
import { default as indexon922FFddbMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/index.vue?macro=true";
import { default as accountdJ4gKJlih3Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/account.vue?macro=true";
import { default as _91id_931hgsAQRSg4Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/billing/invoice/[id].vue?macro=true";
import { default as billingtudoPgERUuMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/billing.vue?macro=true";
import { default as _91cid_931TyJVfVqHSMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/chat/[kind]/[cid].vue?macro=true";
import { default as _91kind_93I3SgJ06OixMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/chat/[kind].vue?macro=true";
import { default as chatE8PUxSaUUaMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/chat.vue?macro=true";
import { default as inactive_45operatorA0JLjZlFCBMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/inactive-operator.vue?macro=true";
import { default as knowledge_45baseNprHXiCug5Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/knowledge-base.vue?macro=true";
import { default as accountlMlrPNZyTOMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/account.vue?macro=true";
import { default as basic_45settingscSkBXTtKI2Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/basic-settings.vue?macro=true";
import { default as operatorsTPWtbOzVYPMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/operators.vue?macro=true";
import { default as basic_45settingsyxxgkttFMlMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/basic-settings.vue?macro=true";
import { default as operatorsiJvWTiqd0DMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/operators.vue?macro=true";
import { default as basic_45settings0B0B0ysl1eMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/basic-settings.vue?macro=true";
import { default as code_45scriptgLGIKsJl8EMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/code-script.vue?macro=true";
import { default as contacts04LGUaHqs4Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/contacts.vue?macro=true";
import { default as operatorsTEyHDY6vmPMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/operators.vue?macro=true";
import { default as personalizationWtfQchW50UMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/personalization.vue?macro=true";
import { default as configure7mAN6GG9gJMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure.vue?macro=true";
import { default as telegramYPWHC6es5UMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/connect/telegram.vue?macro=true";
import { default as viberOtWK7zflU8Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/connect/viber.vue?macro=true";
import { default as channel2eMfVk1yJNMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel.vue?macro=true";
import { default as settingsV6ftbw0ompMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/settings.vue?macro=true";
import { default as work_45analyticsDNu9xLF6StMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-analytics.vue?macro=true";
import { default as work_45scheduleiG4VFdL1ToMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-schedule.vue?macro=true";
import { default as editTbwVEBrGEtMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit.vue?macro=true";
import { default as createQaL8mBHGn2Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator/create.vue?macro=true";
import { default as operatoroHWfLmZPQOMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator.vue?macro=true";
import { default as logopEObYA0EVsMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/logo.vue?macro=true";
import { default as settingsR7UzBZLJK9Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/settings.vue?macro=true";
import { default as editb3nxHx8AsbMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit.vue?macro=true";
import { default as create1qUYYiaUIpMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/project/create.vue?macro=true";
import { default as projectltIkXZpNpBMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/project.vue?macro=true";
import { default as basic_45settingsdFuJ3ccq8JMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/basic-settings.vue?macro=true";
import { default as personalizationaEhPzKbJROMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/personalization.vue?macro=true";
import { default as seo_45and_45analyticsxQIuTnAAX1Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/seo-and-analytics.vue?macro=true";
import { default as editoSSkQQS8qPMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/quick-answer/[id]/edit.vue?macro=true";
import { default as createSKXKjN2lNzMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/quick-answer/create.vue?macro=true";
import { default as quick_45answer6pRsUgZ0qgMeta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/quick-answer.vue?macro=true";
import { default as settingVjtFE06Pa0Meta } from "/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting.vue?macro=true";
export default [
  {
    name: _91token_93yVY2RBBQ6pMeta?.name ?? "language-auth-change-password-token",
    path: _91token_93yVY2RBBQ6pMeta?.path ?? "/:language?/auth/change-password/:token()",
    meta: _91token_93yVY2RBBQ6pMeta || {},
    alias: _91token_93yVY2RBBQ6pMeta?.alias || [],
    redirect: _91token_93yVY2RBBQ6pMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/change-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93_46clientctIuYPcWVnMeta?.name ?? "language-auth-confirm-token",
    path: _91token_93_46clientctIuYPcWVnMeta?.path ?? "/:language?/auth/confirm/:token()",
    meta: _91token_93_46clientctIuYPcWVnMeta || {},
    alias: _91token_93_46clientctIuYPcWVnMeta?.alias || [],
    redirect: _91token_93_46clientctIuYPcWVnMeta?.redirect,
    component: () => createClientPage(() => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/confirm/[token].client.vue").then(m => m.default || m))
  },
  {
    name: _91token_93uuapwkHNVwMeta?.name ?? "language-auth-create-password-token",
    path: _91token_93uuapwkHNVwMeta?.path ?? "/:language?/auth/create-password/:token()",
    meta: _91token_93uuapwkHNVwMeta || {},
    alias: _91token_93uuapwkHNVwMeta?.alias || [],
    redirect: _91token_93uuapwkHNVwMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/create-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93_46clientxetYjGe2Z2Meta?.name ?? "language-auth-invite-token",
    path: _91token_93_46clientxetYjGe2Z2Meta?.path ?? "/:language?/auth/invite/:token()",
    meta: _91token_93_46clientxetYjGe2Z2Meta || {},
    alias: _91token_93_46clientxetYjGe2Z2Meta?.alias || [],
    redirect: _91token_93_46clientxetYjGe2Z2Meta?.redirect,
    component: () => createClientPage(() => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/invite/[token].client.vue").then(m => m.default || m))
  },
  {
    name: google_46clientCmOl2UWYQsMeta?.name ?? "language-auth-oauth-google",
    path: google_46clientCmOl2UWYQsMeta?.path ?? "/:language?/auth/oauth/google",
    meta: google_46clientCmOl2UWYQsMeta || {},
    alias: google_46clientCmOl2UWYQsMeta?.alias || [],
    redirect: google_46clientCmOl2UWYQsMeta?.redirect,
    component: () => createClientPage(() => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/oauth/google.client.vue").then(m => m.default || m))
  },
  {
    name: _91token_93n7FvQdaGfIMeta?.name ?? "language-auth-reset-password-token",
    path: _91token_93n7FvQdaGfIMeta?.path ?? "/:language?/auth/reset-password/:token()",
    meta: _91token_93n7FvQdaGfIMeta || {},
    alias: _91token_93n7FvQdaGfIMeta?.alias || [],
    redirect: _91token_93n7FvQdaGfIMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: requestqxBr7CUOwgMeta?.name ?? "language-auth-reset-password-request",
    path: requestqxBr7CUOwgMeta?.path ?? "/:language?/auth/reset-password/request",
    meta: requestqxBr7CUOwgMeta || {},
    alias: requestqxBr7CUOwgMeta?.alias || [],
    redirect: requestqxBr7CUOwgMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/reset-password/request.vue").then(m => m.default || m)
  },
  {
    name: sign_45inTNMqkf4BhoMeta?.name ?? "language-auth-sign-in",
    path: sign_45inTNMqkf4BhoMeta?.path ?? "/:language?/auth/sign-in",
    meta: sign_45inTNMqkf4BhoMeta || {},
    alias: sign_45inTNMqkf4BhoMeta?.alias || [],
    redirect: sign_45inTNMqkf4BhoMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: indexxK9ebrXpbNMeta?.name ?? "language-auth-sign-up",
    path: indexxK9ebrXpbNMeta?.path ?? "/:language?/auth/sign-up",
    meta: indexxK9ebrXpbNMeta || {},
    alias: indexxK9ebrXpbNMeta?.alias || [],
    redirect: indexxK9ebrXpbNMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: stepPUnzs1bLMTMeta?.name ?? "language-auth-sign-up-step",
    path: stepPUnzs1bLMTMeta?.path ?? "/:language?/auth/sign-up/step",
    meta: stepPUnzs1bLMTMeta || {},
    alias: stepPUnzs1bLMTMeta?.alias || [],
    redirect: stepPUnzs1bLMTMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/step.vue").then(m => m.default || m),
    children: [
  {
    name: _1HoKEKdpIV7Meta?.name ?? "language-auth-sign-up-step-1",
    path: _1HoKEKdpIV7Meta?.path ?? "1",
    meta: _1HoKEKdpIV7Meta || {},
    alias: _1HoKEKdpIV7Meta?.alias || [],
    redirect: _1HoKEKdpIV7Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/step/1.vue").then(m => m.default || m)
  },
  {
    name: _2c2Rv74o3xIMeta?.name ?? "language-auth-sign-up-step-2",
    path: _2c2Rv74o3xIMeta?.path ?? "2",
    meta: _2c2Rv74o3xIMeta || {},
    alias: _2c2Rv74o3xIMeta?.alias || [],
    redirect: _2c2Rv74o3xIMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/step/2.vue").then(m => m.default || m)
  },
  {
    name: _393xamtg2m1Meta?.name ?? "language-auth-sign-up-step-3",
    path: _393xamtg2m1Meta?.path ?? "3",
    meta: _393xamtg2m1Meta || {},
    alias: _393xamtg2m1Meta?.alias || [],
    redirect: _393xamtg2m1Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/step/3.vue").then(m => m.default || m)
  },
  {
    name: _4wOAUX529NuMeta?.name ?? "language-auth-sign-up-step-4",
    path: _4wOAUX529NuMeta?.path ?? "4",
    meta: _4wOAUX529NuMeta || {},
    alias: _4wOAUX529NuMeta?.alias || [],
    redirect: _4wOAUX529NuMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/[[language]]/auth/sign-up/step/4.vue").then(m => m.default || m)
  }
]
  },
  {
    name: widget_45simulator_45_91token_93_46clientfwf9VAq7HPMeta?.name ?? "external-widget-simulator-token",
    path: widget_45simulator_45_91token_93_46clientfwf9VAq7HPMeta?.path ?? "/external/widget-simulator-:token()",
    meta: widget_45simulator_45_91token_93_46clientfwf9VAq7HPMeta || {},
    alias: widget_45simulator_45_91token_93_46clientfwf9VAq7HPMeta?.alias || [],
    redirect: widget_45simulator_45_91token_93_46clientfwf9VAq7HPMeta?.redirect,
    component: () => createClientPage(() => import("/codebuild/output/src1727701116/src/spilky-client/pages/external/widget-simulator-[token].client.vue").then(m => m.default || m))
  },
  {
    name: indexon922FFddbMeta?.name ?? "index",
    path: indexon922FFddbMeta?.path ?? "/",
    meta: indexon922FFddbMeta || {},
    alias: indexon922FFddbMeta?.alias || [],
    redirect: indexon922FFddbMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: accountdJ4gKJlih3Meta?.name ?? "p-pid-account",
    path: accountdJ4gKJlih3Meta?.path ?? "/p/:pid()/account",
    meta: accountdJ4gKJlih3Meta || {},
    alias: accountdJ4gKJlih3Meta?.alias || [],
    redirect: accountdJ4gKJlih3Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/account.vue").then(m => m.default || m)
  },
  {
    name: billingtudoPgERUuMeta?.name ?? "p-pid-billing",
    path: billingtudoPgERUuMeta?.path ?? "/p/:pid()/billing",
    meta: billingtudoPgERUuMeta || {},
    alias: billingtudoPgERUuMeta?.alias || [],
    redirect: billingtudoPgERUuMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/billing.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_931hgsAQRSg4Meta?.name ?? "p-pid-billing-invoice-id",
    path: _91id_931hgsAQRSg4Meta?.path ?? "invoice/:id()",
    meta: _91id_931hgsAQRSg4Meta || {},
    alias: _91id_931hgsAQRSg4Meta?.alias || [],
    redirect: _91id_931hgsAQRSg4Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/billing/invoice/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: chatE8PUxSaUUaMeta?.name ?? "p-pid-chat",
    path: chatE8PUxSaUUaMeta?.path ?? "/p/:pid()/chat",
    meta: chatE8PUxSaUUaMeta || {},
    alias: chatE8PUxSaUUaMeta?.alias || [],
    redirect: chatE8PUxSaUUaMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/chat.vue").then(m => m.default || m),
    children: [
  {
    name: _91kind_93I3SgJ06OixMeta?.name ?? "p-pid-chat-kind",
    path: _91kind_93I3SgJ06OixMeta?.path ?? ":kind()",
    meta: _91kind_93I3SgJ06OixMeta || {},
    alias: _91kind_93I3SgJ06OixMeta?.alias || [],
    redirect: _91kind_93I3SgJ06OixMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/chat/[kind].vue").then(m => m.default || m),
    children: [
  {
    name: _91cid_931TyJVfVqHSMeta?.name ?? "p-pid-chat-kind-cid",
    path: _91cid_931TyJVfVqHSMeta?.path ?? ":cid()",
    meta: _91cid_931TyJVfVqHSMeta || {},
    alias: _91cid_931TyJVfVqHSMeta?.alias || [],
    redirect: _91cid_931TyJVfVqHSMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/chat/[kind]/[cid].vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: inactive_45operatorA0JLjZlFCBMeta?.name ?? "p-pid-inactive-operator",
    path: inactive_45operatorA0JLjZlFCBMeta?.path ?? "/p/:pid()/inactive-operator",
    meta: inactive_45operatorA0JLjZlFCBMeta || {},
    alias: inactive_45operatorA0JLjZlFCBMeta?.alias || [],
    redirect: inactive_45operatorA0JLjZlFCBMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/inactive-operator.vue").then(m => m.default || m)
  },
  {
    name: knowledge_45baseNprHXiCug5Meta?.name ?? "p-pid-knowledge-base",
    path: knowledge_45baseNprHXiCug5Meta?.path ?? "/p/:pid()/knowledge-base",
    meta: knowledge_45baseNprHXiCug5Meta || {},
    alias: knowledge_45baseNprHXiCug5Meta?.alias || [],
    redirect: knowledge_45baseNprHXiCug5Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/knowledge-base.vue").then(m => m.default || m)
  },
  {
    name: settingVjtFE06Pa0Meta?.name ?? "p-pid-setting",
    path: settingVjtFE06Pa0Meta?.path ?? "/p/:pid()/setting",
    meta: settingVjtFE06Pa0Meta || {},
    alias: settingVjtFE06Pa0Meta?.alias || [],
    redirect: settingVjtFE06Pa0Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting.vue").then(m => m.default || m),
    children: [
  {
    name: accountlMlrPNZyTOMeta?.name ?? "p-pid-setting-account",
    path: accountlMlrPNZyTOMeta?.path ?? "account",
    meta: accountlMlrPNZyTOMeta || {},
    alias: accountlMlrPNZyTOMeta?.alias || [],
    redirect: accountlMlrPNZyTOMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/account.vue").then(m => m.default || m)
  },
  {
    name: channel2eMfVk1yJNMeta?.name ?? "p-pid-setting-channel",
    path: channel2eMfVk1yJNMeta?.path ?? "channel",
    meta: channel2eMfVk1yJNMeta || {},
    alias: channel2eMfVk1yJNMeta?.alias || [],
    redirect: channel2eMfVk1yJNMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel.vue").then(m => m.default || m),
    children: [
  {
    name: configure7mAN6GG9gJMeta?.name ?? "p-pid-setting-channel-id-configure",
    path: configure7mAN6GG9gJMeta?.path ?? ":id()/configure",
    meta: configure7mAN6GG9gJMeta || {},
    alias: configure7mAN6GG9gJMeta?.alias || [],
    redirect: configure7mAN6GG9gJMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure.vue").then(m => m.default || m),
    children: [
  {
    name: basic_45settingscSkBXTtKI2Meta?.name ?? "p-pid-setting-channel-id-configure-telegram-basic-settings",
    path: basic_45settingscSkBXTtKI2Meta?.path ?? "telegram/basic-settings",
    meta: basic_45settingscSkBXTtKI2Meta || {},
    alias: basic_45settingscSkBXTtKI2Meta?.alias || [],
    redirect: basic_45settingscSkBXTtKI2Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/basic-settings.vue").then(m => m.default || m)
  },
  {
    name: operatorsTPWtbOzVYPMeta?.name ?? "p-pid-setting-channel-id-configure-telegram-operators",
    path: operatorsTPWtbOzVYPMeta?.path ?? "telegram/operators",
    meta: operatorsTPWtbOzVYPMeta || {},
    alias: operatorsTPWtbOzVYPMeta?.alias || [],
    redirect: operatorsTPWtbOzVYPMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/operators.vue").then(m => m.default || m)
  },
  {
    name: basic_45settingsyxxgkttFMlMeta?.name ?? "p-pid-setting-channel-id-configure-viber-basic-settings",
    path: basic_45settingsyxxgkttFMlMeta?.path ?? "viber/basic-settings",
    meta: basic_45settingsyxxgkttFMlMeta || {},
    alias: basic_45settingsyxxgkttFMlMeta?.alias || [],
    redirect: basic_45settingsyxxgkttFMlMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/basic-settings.vue").then(m => m.default || m)
  },
  {
    name: operatorsiJvWTiqd0DMeta?.name ?? "p-pid-setting-channel-id-configure-viber-operators",
    path: operatorsiJvWTiqd0DMeta?.path ?? "viber/operators",
    meta: operatorsiJvWTiqd0DMeta || {},
    alias: operatorsiJvWTiqd0DMeta?.alias || [],
    redirect: operatorsiJvWTiqd0DMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/operators.vue").then(m => m.default || m)
  },
  {
    name: basic_45settings0B0B0ysl1eMeta?.name ?? "p-pid-setting-channel-id-configure-widget-basic-settings",
    path: basic_45settings0B0B0ysl1eMeta?.path ?? "widget/basic-settings",
    meta: basic_45settings0B0B0ysl1eMeta || {},
    alias: basic_45settings0B0B0ysl1eMeta?.alias || [],
    redirect: basic_45settings0B0B0ysl1eMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/basic-settings.vue").then(m => m.default || m)
  },
  {
    name: code_45scriptgLGIKsJl8EMeta?.name ?? "p-pid-setting-channel-id-configure-widget-code-script",
    path: code_45scriptgLGIKsJl8EMeta?.path ?? "widget/code-script",
    meta: code_45scriptgLGIKsJl8EMeta || {},
    alias: code_45scriptgLGIKsJl8EMeta?.alias || [],
    redirect: code_45scriptgLGIKsJl8EMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/code-script.vue").then(m => m.default || m)
  },
  {
    name: contacts04LGUaHqs4Meta?.name ?? "p-pid-setting-channel-id-configure-widget-contacts",
    path: contacts04LGUaHqs4Meta?.path ?? "widget/contacts",
    meta: contacts04LGUaHqs4Meta || {},
    alias: contacts04LGUaHqs4Meta?.alias || [],
    redirect: contacts04LGUaHqs4Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/contacts.vue").then(m => m.default || m)
  },
  {
    name: operatorsTEyHDY6vmPMeta?.name ?? "p-pid-setting-channel-id-configure-widget-operators",
    path: operatorsTEyHDY6vmPMeta?.path ?? "widget/operators",
    meta: operatorsTEyHDY6vmPMeta || {},
    alias: operatorsTEyHDY6vmPMeta?.alias || [],
    redirect: operatorsTEyHDY6vmPMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/operators.vue").then(m => m.default || m)
  },
  {
    name: personalizationWtfQchW50UMeta?.name ?? "p-pid-setting-channel-id-configure-widget-personalization",
    path: personalizationWtfQchW50UMeta?.path ?? "widget/personalization",
    meta: personalizationWtfQchW50UMeta || {},
    alias: personalizationWtfQchW50UMeta?.alias || [],
    redirect: personalizationWtfQchW50UMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/personalization.vue").then(m => m.default || m)
  }
]
  },
  {
    name: telegramYPWHC6es5UMeta?.name ?? "p-pid-setting-channel-connect-telegram",
    path: telegramYPWHC6es5UMeta?.path ?? "connect/telegram",
    meta: telegramYPWHC6es5UMeta || {},
    alias: telegramYPWHC6es5UMeta?.alias || [],
    redirect: telegramYPWHC6es5UMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/connect/telegram.vue").then(m => m.default || m)
  },
  {
    name: viberOtWK7zflU8Meta?.name ?? "p-pid-setting-channel-connect-viber",
    path: viberOtWK7zflU8Meta?.path ?? "connect/viber",
    meta: viberOtWK7zflU8Meta || {},
    alias: viberOtWK7zflU8Meta?.alias || [],
    redirect: viberOtWK7zflU8Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/channel/connect/viber.vue").then(m => m.default || m)
  }
]
  },
  {
    name: operatoroHWfLmZPQOMeta?.name ?? "p-pid-setting-operator",
    path: operatoroHWfLmZPQOMeta?.path ?? "operator",
    meta: operatoroHWfLmZPQOMeta || {},
    alias: operatoroHWfLmZPQOMeta?.alias || [],
    redirect: operatoroHWfLmZPQOMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator.vue").then(m => m.default || m),
    children: [
  {
    name: editTbwVEBrGEtMeta?.name ?? "p-pid-setting-operator-id-edit",
    path: editTbwVEBrGEtMeta?.path ?? ":id()/edit",
    meta: editTbwVEBrGEtMeta || {},
    alias: editTbwVEBrGEtMeta?.alias || [],
    redirect: editTbwVEBrGEtMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: settingsV6ftbw0ompMeta?.name ?? "p-pid-setting-operator-id-edit-settings",
    path: settingsV6ftbw0ompMeta?.path ?? "settings",
    meta: settingsV6ftbw0ompMeta || {},
    alias: settingsV6ftbw0ompMeta?.alias || [],
    redirect: settingsV6ftbw0ompMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/settings.vue").then(m => m.default || m)
  },
  {
    name: work_45analyticsDNu9xLF6StMeta?.name ?? "p-pid-setting-operator-id-edit-work-analytics",
    path: work_45analyticsDNu9xLF6StMeta?.path ?? "work-analytics",
    meta: work_45analyticsDNu9xLF6StMeta || {},
    alias: work_45analyticsDNu9xLF6StMeta?.alias || [],
    redirect: work_45analyticsDNu9xLF6StMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-analytics.vue").then(m => m.default || m)
  },
  {
    name: work_45scheduleiG4VFdL1ToMeta?.name ?? "p-pid-setting-operator-id-edit-work-schedule",
    path: work_45scheduleiG4VFdL1ToMeta?.path ?? "work-schedule",
    meta: work_45scheduleiG4VFdL1ToMeta || {},
    alias: work_45scheduleiG4VFdL1ToMeta?.alias || [],
    redirect: work_45scheduleiG4VFdL1ToMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-schedule.vue").then(m => m.default || m)
  }
]
  },
  {
    name: createQaL8mBHGn2Meta?.name ?? "p-pid-setting-operator-create",
    path: createQaL8mBHGn2Meta?.path ?? "create",
    meta: createQaL8mBHGn2Meta || {},
    alias: createQaL8mBHGn2Meta?.alias || [],
    redirect: createQaL8mBHGn2Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/operator/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: projectltIkXZpNpBMeta?.name ?? "p-pid-setting-project",
    path: projectltIkXZpNpBMeta?.path ?? "project",
    meta: projectltIkXZpNpBMeta || {},
    alias: projectltIkXZpNpBMeta?.alias || [],
    redirect: projectltIkXZpNpBMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/project.vue").then(m => m.default || m),
    children: [
  {
    name: editb3nxHx8AsbMeta?.name ?? "p-pid-setting-project-id-edit",
    path: editb3nxHx8AsbMeta?.path ?? ":id()/edit",
    meta: editb3nxHx8AsbMeta || {},
    alias: editb3nxHx8AsbMeta?.alias || [],
    redirect: editb3nxHx8AsbMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: logopEObYA0EVsMeta?.name ?? "p-pid-setting-project-id-edit-logo",
    path: logopEObYA0EVsMeta?.path ?? "logo",
    meta: logopEObYA0EVsMeta || {},
    alias: logopEObYA0EVsMeta?.alias || [],
    redirect: logopEObYA0EVsMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/logo.vue").then(m => m.default || m)
  },
  {
    name: settingsR7UzBZLJK9Meta?.name ?? "p-pid-setting-project-id-edit-settings",
    path: settingsR7UzBZLJK9Meta?.path ?? "settings",
    meta: settingsR7UzBZLJK9Meta || {},
    alias: settingsR7UzBZLJK9Meta?.alias || [],
    redirect: settingsR7UzBZLJK9Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: create1qUYYiaUIpMeta?.name ?? "p-pid-setting-project-create",
    path: create1qUYYiaUIpMeta?.path ?? "create",
    meta: create1qUYYiaUIpMeta || {},
    alias: create1qUYYiaUIpMeta?.alias || [],
    redirect: create1qUYYiaUIpMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/project/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: basic_45settingsdFuJ3ccq8JMeta?.name ?? "p-pid-setting-public-knowledge-base-basic-settings",
    path: basic_45settingsdFuJ3ccq8JMeta?.path ?? "public-knowledge-base/basic-settings",
    meta: basic_45settingsdFuJ3ccq8JMeta || {},
    alias: basic_45settingsdFuJ3ccq8JMeta?.alias || [],
    redirect: basic_45settingsdFuJ3ccq8JMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/basic-settings.vue").then(m => m.default || m)
  },
  {
    name: personalizationaEhPzKbJROMeta?.name ?? "p-pid-setting-public-knowledge-base-personalization",
    path: personalizationaEhPzKbJROMeta?.path ?? "public-knowledge-base/personalization",
    meta: personalizationaEhPzKbJROMeta || {},
    alias: personalizationaEhPzKbJROMeta?.alias || [],
    redirect: personalizationaEhPzKbJROMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/personalization.vue").then(m => m.default || m)
  },
  {
    name: seo_45and_45analyticsxQIuTnAAX1Meta?.name ?? "p-pid-setting-public-knowledge-base-seo-and-analytics",
    path: seo_45and_45analyticsxQIuTnAAX1Meta?.path ?? "public-knowledge-base/seo-and-analytics",
    meta: seo_45and_45analyticsxQIuTnAAX1Meta || {},
    alias: seo_45and_45analyticsxQIuTnAAX1Meta?.alias || [],
    redirect: seo_45and_45analyticsxQIuTnAAX1Meta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/seo-and-analytics.vue").then(m => m.default || m)
  },
  {
    name: quick_45answer6pRsUgZ0qgMeta?.name ?? "p-pid-setting-quick-answer",
    path: quick_45answer6pRsUgZ0qgMeta?.path ?? "quick-answer",
    meta: quick_45answer6pRsUgZ0qgMeta || {},
    alias: quick_45answer6pRsUgZ0qgMeta?.alias || [],
    redirect: quick_45answer6pRsUgZ0qgMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/quick-answer.vue").then(m => m.default || m),
    children: [
  {
    name: editoSSkQQS8qPMeta?.name ?? "p-pid-setting-quick-answer-id-edit",
    path: editoSSkQQS8qPMeta?.path ?? ":id()/edit",
    meta: editoSSkQQS8qPMeta || {},
    alias: editoSSkQQS8qPMeta?.alias || [],
    redirect: editoSSkQQS8qPMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/quick-answer/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createSKXKjN2lNzMeta?.name ?? "p-pid-setting-quick-answer-create",
    path: createSKXKjN2lNzMeta?.path ?? "create",
    meta: createSKXKjN2lNzMeta || {},
    alias: createSKXKjN2lNzMeta?.alias || [],
    redirect: createSKXKjN2lNzMeta?.redirect,
    component: () => import("/codebuild/output/src1727701116/src/spilky-client/pages/p/[pid]/setting/quick-answer/create.vue").then(m => m.default || m)
  }
]
  }
]
  }
]