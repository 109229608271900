import { useAppStore } from '~/stores/app'
import { useUserStore } from '~/stores/user'
import { useChatStore } from '~/stores/chat'
import pluginCheckContext from '~/helpers/pluginCheckContext'

export default defineNuxtPlugin(async (nuxtApp) => {
    const appStore = useAppStore()
    const userStore = useUserStore()

    if (useError().value || !pluginCheckContext(nuxtApp.$router as any, userStore)) {
        return
    }

    const { asyncConnect, disconnect } = useBroadcaster()

    const fetchData = (chatStore: ReturnType<typeof useChatStore>): Promise<void> => {
        if (!userStore.hasAccessToCabinet || appStore.dataFetched) {
            return
        }

        appStore.dataFetched = true

        return chatStore.init()
    }

    const runDependencies = async (chatStore: ReturnType<typeof useChatStore>): Promise<void> => {
        if (!userStore.hasAccessToCabinet) {
            return
        }

        await asyncConnect({ waitOneAttempt: true, waitMaxMs: 2000 })

        userStore.handleOperatorOfflineStatus(true)
        chatStore.handleTransferredChats()

        onNuxtReady(() => {
            appStore.setSystemAlertEnableNotificationsIfNecessary()
        })
    }

    const onIn = async (): Promise<void> => {
        const chatStore = useChatStore()

        await fetchData(chatStore)
        await runDependencies(chatStore)
    }

    if (process.client) {
        // TODO перевірити необхідність на дев (з реальним зʼєднанням, а не localhost)
        const { onOffline } = useNetwork()

        onOffline(() => disconnect())
    }

    if (userStore.hasAccessToCabinet) {
        const chatStore = useChatStore()

        // Просто перевірка на !!process.server не підходить,
        // бо під час підключення у налаштуваннях сервісу для авторизації при перенаправленні в кабінет
        // користувач вже фактично авторизований та процес не відбувається на сервері
        if (!appStore.dataFetched) {
            await fetchData(chatStore)
        }

        if (process.client) {
            await runDependencies(chatStore)
        }
    } else {
        userStore.onIn(onIn)
    }
})
