import type { AppItemTimezone } from '~/ts/types/app'

export default {
    getTimezones() {
        return useAppFetch<AppItemTimezone[]>('/setting/timezones')
    },
    getWidgetConfig() {
        return useAppFetch<any>('/setting/widget-config')
    }
}
