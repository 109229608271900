<script
    lang="ts"
    setup
>
    import { useAppStore } from '~/stores/app'

    const appStore = useAppStore()

    const { public: { isProd } } = useRuntimeConfig()
    const { isOnline } = useNetwork()
    const { t } = useLang()
    const confirmContext = useConfirmContext()

    const seoMetaThemeColor = computed<string>(() => {
        if (appStore.showLoader) {
            return '#f6f7f8'
        }

        return appStore.bannerBgColor || '#fff'
    })

    useSeoMeta({
        themeColor: seoMetaThemeColor
    })

    const handleContentUpdatedModalError = (error) => {
        // eslint-disable-next-line no-console
        !isProd && console.error(error)

        try {
            useNotify().push({
                type: 'info',
                text: t('content-updated-modal-1') + ' (E2)',
                afterPageReload: true
            })
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn('Content updated (E2)')
        }

        location.reload()
    }
</script>

<template>
    <NuxtLayout>
        <NuxtPage/>

        <NuxtErrorBoundary
            key="content-updated-modal-error"
            @error="handleContentUpdatedModalError"
        >
            <TheContentUpdatedModal
                v-if="appStore.showContentUpdatedModal"
                key="content-updated-modal"
            />
        </NuxtErrorBoundary>

        <ClientOnly>
            <TheOfflineModal
                v-if="!isOnline"
                key="offline-modal"
            />

            <TheSnackbar key="snackbar"/>

            <AppConfirm
                v-if="confirmContext.callback"
                key="confirm"
            />
        </ClientOnly>

        <Transition name="fade-transition">
            <TheLoader
                v-if="appStore.showLoader"
                key="loader"
            />
        </Transition>
    </NuxtLayout>
</template>
