import { defineStore } from 'pinia'
import type * as userTypes from '~/ts/types/user'
import type * as authTypes from '~/ts/types/auth'
import type { OptionalExcept } from '~/ts/types/common'
import type { Project } from '~/ts/types/project'
import type { Operator } from '~/ts/types/operator'
import type { Subscription } from '~/ts/types/subscription'
import { AppItemRoleEnum } from '~/ts/enums/app'
import navigateToHome from '~/helpers/navigateToHome'
import getUserContextFromHandshake from '~/helpers/getUserContextFromHandshake'

export const useUserStore = defineStore('userStore', () => {
    const api = useApi()
    const lang = useLang()
    const notify = useNotify()
    const { currentRoute, resolve: routerResolve } = useRouter()
    const operatorOfflineStatusTimer = shallowRef<ReturnType<typeof useTimer> | undefined>()

    const user = ref<userTypes.User>({} as userTypes.User)
    const projects = ref<Project[]>([])
    const operators = ref<Operator[]>([])
    const handshakeUnread = ref<authTypes.AuthHandshakeReturnValue['unread']>([])
    const hooks = {
        onIn: createEventHook(),
        onOut: createEventHook(),
        onOperatorOfflineStatusChanged: createEventHook()
    }

    const projectIds = computed<number[]>(() => projects.value.map(v => v.id))
    const myProjects = computed<Project[]>(() => {
        return projects.value.filter(v => user.value.id === v.user_id)
    })
    const myProjectsIds = computed<number[]>(() => myProjects.value.map(v => v.id))
    const guestProjects = computed<Project[]>(() => {
        return projects.value.filter(v => user.value.id !== v.user_id)
    })
    const projectById = computed<Record<number, Project>>(() => {
        return projects.value.reduce((result, value) => {
            result[value.id] = value

            return result
        }, {})
    })
    const operatorByProjectId = computed<Record<number, Operator>>(() => {
        return operators.value.reduce((result, value) => {
            result[value.project_id] = value

            return result
        }, {})
    })
    const roleIdByProjectId = computed<Record<number, number>>(() => {
        return operators.value.reduce((result, value) => {
            result[value.project_id] = value.role

            return result
        }, {})
    })

    const currentPid = ref<number>(+currentRoute.value.params.pid || +useAppCookie('project'))
    const isCurrentPageAuth = computed<boolean>(() => {
        if (isArray(currentRoute.value.meta.middleware)) {
            return currentRoute.value.meta.middleware.includes('auth')
        }

        return currentRoute.value.meta.middleware === 'auth'
    })
    const currentProject = computed<Project>(() => {
        return projectById.value[currentPid.value]
            || (isCurrentPageAuth.value ? myProjects.value[0] : projects.value[0])
    })
    const currentOperator = computed<Operator | undefined>(() => operatorByProjectId.value[currentProject.value?.id])
    const activeSubscription = computed<Subscription | undefined>(() => currentProject.value?.subscription || undefined)
    const isAuthenticated = computed<boolean>(() => !!user.value.id)
    const isRegistrationCompleted = computed<boolean>(() => isAuthenticated.value && !user.value.signup_step)
    const isOperatorEnabled = computed<boolean>(() => !!currentOperator.value?.enabled)
    const isOperatorOnline = computed<boolean>(() => isNull(currentOperator.value?.offline_status))
    const isAdministrator = computed<boolean>(() => (
        roleIdByProjectId.value[currentProject.value?.id] === AppItemRoleEnum.Admin
    ))
    const isoOwner = computed<boolean>(() => {
        if (!currentProject.value || currentOperator.value) {
            return false
        }

        return currentProject.value.user_id === currentOperator.value.user_id
    })
    const hasAccessToCabinet = computed<boolean>(() => {
        return isAuthenticated.value
            && isRegistrationCompleted.value
            && isOperatorEnabled.value
    })
    const handshakeUnreadInCurrentProject = computed<authTypes.AuthHandshakeReturnValue['unread']>(() => {
        return handshakeUnread.value.filter(v => v.project_id === currentProject.value.id)
    })
    const handshakeUnreadInOtherProjects = computed<authTypes.AuthHandshakeReturnValue['unread']>(() => {
        return handshakeUnread.value.filter(v => v.project_id !== currentProject.value.id)
    })

    const handshake = async (options: authTypes.AuthHandshake & { ignoreAuthError?: boolean } = {}): Promise<void> => {
        if (!options.expand) {
            options.expand = {
                project: true,
                onboarding: true,
                need_create_project: true,
                schedule: true
            }
        }

        const { data, error } = await api.auth.handshake(options)

        if (error.value) {
            if (
                error.value.statusCode === 401
                && options.ignoreAuthError
            ) {
                return
            }

            throw error.value
        }

        const context = getUserContextFromHandshake(data.value)

        updateUserData(context.user)

        projects.value = context.projects
        operators.value = context.operators
    }

    const login = async (payload: authTypes.AuthLogin): Promise<void> => {
        const { data, error } = await api.auth.login(payload)

        if (error.value) {
            throw error
        }

        useAppCookie('token').value = data.value['access_token']
        useAppCookie('refreshToken').value = data.value['refresh_token']

        await nextTick() // Має бути для закінчення оновлення кукі

        try {
            await handshake()
        } catch (error) {
            throw showError(error)
        }

        await hooks.onIn.trigger()

        navigateToHome()
    }

    const register = async (payload: authTypes.AuthRegister): Promise<void> => {
        if (!payload.expand) {
            payload.expand = {
                need_create_project: true
            }
        }

        const { data, error } = await api.auth.register(payload)

        if (error.value) {
            throw error
        }

        useAppCookie('token').value = data.value['access_token']
        useAppCookie('refreshToken').value = data.value['refresh_token']

        user.value = data.value.user

        navigateTo({ name: 'language-auth-sign-up-step-1' })
    }

    const logout = async (): Promise<void> => {
        useAppCookie('token').value = null
        useAppCookie('refreshToken').value = null

        await hooks.onOut.trigger()

        location.href = routerResolve({ name: 'language-auth-sign-in' }).fullPath
    }

    const update = async (payload: userTypes.UserUpdate['data']): Promise<void> => {
        const { data, error } = await api.user.update({ data: payload })

        if (error.value) {
            throw error
        }

        updateUserData(data.value)
    }

    const updateUserData = (data: Partial<userTypes.User>): void => {
        user.value = {
            ...user.value,
            ...data
        }
    }

    const updateOrAddUserProject = (project: OptionalExcept<Project, 'id'> | Project): void => {
        const index = projects.value.findIndex(v => v.id === project.id)

        if (index === -1) {
            projects.value.push(project as Project)

            return
        }

        Object.assign(projects.value[index], project)
    }

    const removeUserProject = (id: Project['id']): void => {
        projects.value = projects.value.filter(v => v.id !== id)
    }

    const updateOnboarding = (key: keyof Operator['onboarding']): void => {
        if (
            !currentOperator.value.onboarding
            || currentOperator.value.onboarding[key]
        ) {
            return
        }

        currentOperator.value.onboarding[key] = true

        api.operator.updateOnboarding({
            operatorId: currentOperator.value.id,
            data: { step: key }
        })
    }

    const handleOperatorOfflineStatus = (setup = false): void => {
        if (!process.client) {
            return
        }

        operatorOfflineStatusTimer.value?.stop()

        if (isOperatorOnline.value) {
            if (!setup) {
                notify.push({
                    tag: 'work-status',
                    type: 'success',
                    text: lang.t('work-status-value-null-description')
                })
            }

            return
        }

        if (currentOperator.value.offline_status === 0) {
            notify.push({
                tag: 'work-status',
                type: 'info',
                text: lang.t('work-status-value-0-description')
            })

            return
        }

        const milliseconds = Math.floor(
            Math.abs(
                dateUtil
                    .rawFromSQL(currentOperator.value.offline_status_updated_at, { zone: 'utc' })
                    .plus({ minutes: currentOperator.value.offline_status })
                    .diffNow('millisecond')
                    .milliseconds
            )
        )

        operatorOfflineStatusTimer.value = useTimer(() => {
            currentOperator.value.offline_status = null

            nextTick(() => hooks.onOperatorOfflineStatusChanged.trigger(null))

            notify.push({
                tag: 'work-status',
                type: 'success',
                text: lang.t('work-status-value-null-description')
            })
        }, milliseconds, { immediate: true })

        notify.push({
            tag: 'work-status',
            type: 'info',
            text: lang.t('offline-until') + ' ' + dateUtilConfig
                .applyConfig(
                    dateUtil
                        .rawFromSQL(currentOperator.value.offline_status_updated_at, { zone: 'utc' })
                        .plus({ minutes: currentOperator.value.offline_status })
                )
                .toFormat(DATE_DISPLAY_TIME_FULL_FORMAT)
        })
    }

    return {
        user,
        projects,
        operators,
        handshakeUnread,
        handshakeUnreadInCurrentProject,
        handshakeUnreadInOtherProjects,
        currentPid,
        currentProject,
        isAuthenticated,
        isRegistrationCompleted,
        isOperatorEnabled,
        isOperatorOnline,
        isAdministrator,
        isoOwner,
        hasAccessToCabinet,
        roleIdByProjectId,
        projectIds,
        myProjects,
        myProjectsIds,
        guestProjects,
        currentOperator,
        activeSubscription,
        handshake,
        login,
        register,
        logout,
        update,
        updateUserData,
        updateOrAddUserProject,
        removeUserProject,
        updateOnboarding,
        handleOperatorOfflineStatus,
        onInTrigger: hooks.onIn.trigger,
        onIn: hooks.onIn.onOnce,
        onOut: hooks.onOut.onOnce,
        onOperatorOfflineStatusChanged: hooks.onOperatorOfflineStatusChanged.on
    }
})
