import { defineStore } from 'pinia'
import type { Optional } from '~/ts/types/common'
import type { AppNotification } from '~/ts/types/app'

export const useNotificationStore = defineStore('notificationStore', () => {
    const DEFAULT_TYPE: AppNotification['type'] = 'info'
    const DEFAULT_TIMEOUT: AppNotification['timeout'] = 5 * 1000

    const sounds = {
        success: useSound('notification-success'),
        info: useSound('notification-info'),
        error: useSound('notification-error')
    }

    const notifications = ref<AppNotification[]>([])
    const cookieNotifications = useAppCookie<AppNotification[]>('notifications')
    const delayedNotifications = shallowRef<AppNotification[]>([])
    const availableNotificationTypes: AppNotification['type'][] = [ 'info', 'success', 'error', 'custom' ]

    const router = useRouter()
    const { isTabActive, onTabActive } = useTabActivity()

    onTabActive(() => {
        while (delayedNotifications.value.length) {
            showNotification(delayedNotifications.value.shift() as AppNotification)
        }
    })

    const showNotification = (notification: AppNotification): void => {
        if (!process.client) {
            return
        }

        if (!isTabActive.value) {
            delayedNotifications.value.push(notification)

            return
        }

        const existingNotification: AppNotification | undefined = notification.tag
            ? notifications.value.find(v => v.tag === (notification as AppNotification).tag)
            : undefined

        if (existingNotification) {
            removeNotification(existingNotification.id)
        }

        if (isUndefined(notification.timeout)) {
            notification.timeout = DEFAULT_TIMEOUT
        }

        if (!notification.silent) {
            sounds[notification.type].play()
        }

        notifications.value.push(notification)

        if (notification.closeOnRouteChange || notification.onRouteBeforeEach) {
            router.beforeEach(async (to, from) => {
                if (notification.onRouteBeforeEach) {
                    await notification.onRouteBeforeEach(notification, to, from)
                }

                if (notification.closeOnRouteChange) {
                    removeNotification(notification.id)
                }
            })
        }
    }

    const pushNotification = (notification: string | Omit<Optional<AppNotification, 'type'>, 'close'>): void => {
        if (isString(notification)) {
            notification = { text: notification }
        }

        const id = notification.id || stringUtil.generateHash()

        const _notification: AppNotification = {
            id,
            silent: true,
            ...notification,
            type: notification.type || DEFAULT_TYPE,
            closeOnClick: notification.closeOnClick ?? true,
            pauseOnHover: notification.pauseOnHover ?? true,
            close: () => removeNotification(_notification.id)
        }

        if (!availableNotificationTypes.includes(_notification.type)) {
            useLog('Unknown type - ' + _notification.type, 'pushNotification', { warning: true })

            _notification.type = DEFAULT_TYPE
        }

        if (process.server || _notification.afterPageReload) {
            cookieNotifications.value = [ ...cookieNotifications.value || [], _notification ]

            return
        }

        showNotification(_notification)
    }

    const removeNotification = (id: AppNotification['id']): void => {
        delayedNotifications.value = delayedNotifications.value.filter(v => v.id !== id)

        const notification = notifications.value.find(v => v.id === id)

        if (!notification) {
            return
        }

        notifications.value = notifications.value.filter(v => v.id !== notification.id)

        notification.onClose?.(notification)
    }

    const processCookieNotifications = (): void => {
        if (!cookieNotifications.value) {
            return
        }

        try {
            cookieNotifications.value.forEach(v => pushNotification({ ...v, afterPageReload: false }))
        } catch (error) {
        }

        cookieNotifications.value = null
    }

    return {
        notifications,
        availableNotificationTypes,
        pushNotification,
        removeNotification,
        processCookieNotifications
    }
})
