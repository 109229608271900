import type { AnyFn } from '@/ts/types/common'

export type MessageSendingQueueSender = AnyFn<Promise<void>>

export class MessageSendingQueue {
    private queue: MessageSendingQueueSender[] = []
    private sending: boolean = false

    private async sendNext(): Promise<void> {
        if (!this.queue.length) {
            this.sending = false

            return
        }

        this.sending = true

        const sender = this.queue.shift()

        await sender()

        this.sendNext()
    }

    public enqueue(sender: MessageSendingQueueSender): void {
        this.queue.push(sender)

        if (!this.sending) {
            this.sendNext()
        }
    }
}
