import type * as planTypes from '~/ts/types/plan'
import type { Invoice } from '~/ts/types/invoice'

export default {
    all({ page = 1, perPage = undefined }: planTypes.PlanAll = {}) {
        return useAppFetch<{ items: planTypes.Plan[] }>('/project/{projectId}/plan', {
            params: {
                page,
                'per-page': perPage
            }
        })
    },
    purchase({ id, operatorQuantity }: planTypes.PlanPurchase) {
        return useAppFetch<Invoice>(`/project/{projectId}/plan/${ id }/purchase`, {
            method: 'post',
            body: {
                quantity: operatorQuantity
            }
        })
    }
}
