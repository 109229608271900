import type * as invoiceTypes from '~/ts/types/invoice'
import type { AppTablePagination } from '~/ts/types/app'

export default {
    all({ page = 1, perPage = undefined }: invoiceTypes.InvoiceAll = {}) {
        return useAppFetch<{ items: invoiceTypes.Invoice[], _meta: AppTablePagination }>('/project/{projectId}/invoice', {
            params: {
                page,
                'per-page': perPage
            }
        })
    },
    one({ id }: invoiceTypes.InvoiceOne) {
        return useAppFetch<invoiceTypes.Invoice>(`/project/{projectId}/invoice/${ id }`)
    },
    payWithCard({ id, cardId }: invoiceTypes.InvoicePayWithCard) {
        return useAppFetch<invoiceTypes.Invoice>(`/project/{projectId}/invoice/${ id }/pay`, {
            method: 'post',
            body: {
                user_card_id: cardId
            }
        })
    },
    payWithWayForPay({ id }: invoiceTypes.InvoicePayWithWayForPay) {
        return useAppFetch<{ url: string }>(`/project/{projectId}/invoice/${ id }/pay-direct`, {
            method: 'post'
        })
    }
}
