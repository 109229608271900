import type { UseAppFetchOptions } from '~/composables/useAppFetch'
import { useUserStore } from '~/stores/user'
import refreshAuthToken from '~/helpers/fetch/refreshAuthToken'

export type SetAuthTokenContext = {
    options: UseAppFetchOptions,
    token: Ref<string>,
    userStore: ReturnType<typeof useUserStore>
}

export default async ({ options, token, userStore }: SetAuthTokenContext): Promise<void> => {
    if (options.skipAuthTokenProcessing) {
        return
    }

    if (validateJWT(token.value)) {
        options.headers['Authorization'] = `Bearer ${ token.value }`

        return
    }

    await refreshAuthToken({ options, token, userStore })
}
