<script
    lang="ts"
    setup
>
    const { maxTablet } = useWindowSize()
</script>

<template>
    <AppModal
        :version-bottom="maxTablet"
        impulsive-transition
        content-class="!max-w-[390px]"
    >
        <template #icon>
            <AppIconInternetOff size="34"/>
        </template>

        <template #title>
            <div class="text-center">
                {{ $t('no-internet-connection') }}
            </div>
        </template>

        <AppHint
            key="hint"
            class="text-center"
        >
            {{ $t('check-your-internet-connection') }}
        </AppHint>
    </AppModal>
</template>
