<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M4 11V13H16L10.5 18.5L11.92 19.92L19.84 12L11.92 4.08002L10.5 5.50002L16 11H4Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
