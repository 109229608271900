import { useAppStore } from '~/stores/app'

export default defineNuxtPlugin((nuxtApp) => {
    let reloading: boolean

    nuxtApp.hook('app:chunkError', () => {
        // Щоб запобігти повторному виклику під час процесу оновлення (наприклад, якщо було запитано кілька пошкоджених файлів)
        if (reloading) {
            return
        }

        reloading = true

        try {
            useAppStore().showContentUpdatedModal = true
        } catch (error) {
            try {
                useNotify().push({
                    type: 'info',
                    text: useLang().t('content-updated-modal-1') + ' (E1)',
                    afterPageReload: true
                })
            } catch (err) {
                // eslint-disable-next-line no-console
                console.warn('Content updated (E1)')
            }

            location.reload()
        }
    })
})
