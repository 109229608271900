<script
    lang="ts"
    setup
>
    type Props = {
        versionBottom?: boolean
        impulsiveTransition?: boolean
        contentClass?: string
        darker?: boolean
    }

    type Emit = {
        (event: 'close'): void
    }

    const props = withDefaults(defineProps<Props>(), {
        versionBottom: false,
        impulsiveTransition: false,
        contentClass: undefined,
        darker: false
    })
    const emit = defineEmits<Emit>()

    const { $popupManager } = useNuxtApp()

    const { close } = $popupManager.registerModal(() => {
        if ($popupManager.isMenuOpen()) {
            $popupManager.closeLastMenu()
        } else {
            emit('close')
        }
    })

    const mounted = ref<boolean>(false)

    const contentTransitionName = computed<string>(() => {
        if (props.versionBottom) {
            return 'slide-y-bottom-transition'
        }

        return props.impulsiveTransition
            ? 'modal-content-impulsive-transition'
            : 'modal-content-transition'
    })

    const backgroundValue = computed<string>(() => {
        return mounted.value ? (props.darker ? 'bg-black/45' : 'bg-black/25') : 'transparent'
    })

    const modalClass = computed<string>(() => {
        let classes = `
            z-[99]
            fixed
            flex
            justify-center
            top-0
            left-0
            w-full
            h-full
            ${ backgroundValue.value }
            transition-[background-color_220ms_var(--transition-default-ease)]
            cursor-pointer
        `

        if (props.versionBottom) {
            classes += ' items-end'
        } else {
            classes += ' items-center p-4'
        }

        return classes
    })

    const contentClass = computed<string>(() => {
        let classes = `
            relative
            flex
            flex-col
            items-center
            p-8
            bg-white
            origin-center
            cursor-auto
        `

        if (props.versionBottom) {
            classes += ' w-full pb-12 rounded-[16px_16px_0_0]'
        } else {
            classes += ' max-w-[364px] rounded-[16px]'
        }

        if (props.contentClass) {
            classes += ' ' + props.contentClass
        }

        return classes
    })

    onMounted(() => {
        setTimeout(() => {
            mounted.value = true
        }, 50)
    })

    onUnmounted(() => useEventBus().emit(BUS_EVENT_APP_MODAL_CLOSED))
</script>

<template>
    <div
        :class="modalClass"
        @click.self="close()"
    >
        <Transition :name="contentTransitionName">
            <div
                v-if="mounted"
                key="content"
                :class="contentClass"
            >
                <div
                    v-if="$slots['icon']"
                    key="icon"
                    class="mb-4"
                >
                    <slot name="icon"/>
                </div>

                <div
                    v-if="$slots['title']"
                    key="title"
                    class="mb-6 text-[20px] font-medium leading-[24px]"
                >
                    <slot name="title"/>
                </div>

                <slot/>
            </div>
        </Transition>
    </div>
</template>

<style lang="sass">
    .modal-content-transition
        &-enter-from
            opacity: 0
            transform: scale(0.94)

        &-enter-active
            pointer-events: none
            transition: opacity 240ms, transform 240ms cubic-bezier(0.39, 0.58, 0.57, 1) !important

        &-enter-to
            opacity: 1
            transform: scale(1)

    .modal-content-impulsive-transition
        &-enter-from
            opacity: 0
            transform: scale(1.1)

        &-enter-active
            pointer-events: none
            transition: opacity 220ms, transform 220ms cubic-bezier(0.25, 0.4, 0.55, 1.4) !important

        &-enter-to
            opacity: 1
            transform: scale(1)
</style>
