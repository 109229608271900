import type * as wsTypes from '~/ts/types/ws'
import type { UseLogExecutor } from '~/composables/useLog'
import { WSServerMessageEventEnum } from '~/ts/enums/ws'
import { Broadcaster } from '~/helpers/ws/Broadcaster'

export class BroadcasterMessageHandler {
    public static handleMessage = (broadcaster: Broadcaster, log: UseLogExecutor, event: MessageEvent): void => {
        log(event.data, 'BroadcasterMessageHandler->handleMessage')

        const message = this.deserializeServerMessage(event.data)

        switch (message.event) {
            case WSServerMessageEventEnum.Connect:
            case WSServerMessageEventEnum.Subscribe:
            case WSServerMessageEventEnum.Unsubscribe:
                break
            case WSServerMessageEventEnum.Publish:
            case WSServerMessageEventEnum.PublishClient:
                this.handleServerMessagePublish(broadcaster, message)
                break
            case WSServerMessageEventEnum.Error:
                this.handleServerMessageError(message)
                break
            default:
                useLog(
                    'Unknown event - ' + message.event,
                    'BroadcasterMessageHandler',
                    { error: true }
                )
        }
    }

    public static serializeMessage = (message: wsTypes.WSMessage): string => {
        const value = [
            message.action,
            message.channel
        ]

        if (message.event) {
            value.push(message.event)
        }

        if (!isEmpty(message.payload, { primitive: true })) {
            value.push(message.payload)
        }

        return JSON.stringify(value)
    }

    public static deserializeMessage = (message: string): wsTypes.WSMessage => {
        const value = JSON.parse(message)

        return {
            action: value[0],
            channel: value[1],
            event: value[2],
            payload: value[3]
        }
    }

    public static deserializeServerMessage = (message: string): wsTypes.WSServerMessage => {
        return JSON.parse(message)
    }

    private static handleServerMessagePublish = (broadcaster: Broadcaster, message: wsTypes.WSServerMessage): void => {
        broadcaster.channels.get(message.channel)?.triggerListener(message.channelEvent, message.payload)
    }

    private static handleServerMessageError = (message: wsTypes.WSServerMessage): void => {
        useLog(
            `(${ message.event }) - ${ message.message }`,
            'BroadcasterMessageHandler',
            { error: true }
        )
    }
}
