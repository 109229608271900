<script
    lang="ts"
    setup
>
    const { maxTablet } = useWindowSize()

    const mounted = ref<boolean>(false)
    const seconds = ref<number>(10)

    const timer = useTimer(
        () => {
            seconds.value--

            if (!seconds.value) {
                timer.stop()

                refreshPage()
            }
        },
        1000,
        { interval: true }
    )

    const contentTransitionName = computed<string>(() => {
        if (maxTablet.value) {
            return 'slide-y-bottom-transition'
        }

        return 'modal-content-impulsive-transition'
    })

    const refreshPage = (): void => {
        location.reload()
    }

    onMounted(() => {
        setTimeout(() => {
            mounted.value = true

            timer.start()
        }, 50)
    })
</script>

<template>
    <div
        :class="[ $style['modal'], { [$style['modal--version-bottom']]: maxTablet } ]"
        @keyup.esc="refreshPage()"
        @click.self="refreshPage()"
    >
        <Transition :name="contentTransitionName">
            <div
                v-if="mounted"
                key="content"
                :class="$style['modal__content']"
            >
                <div :class="$style['modal__content__icon']">
                    <svg
                        width="34"
                        height="34"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style="min-width: 34px; min-height: 34px"
                    >
                        <!-- eslint-disable max-len -->
                        <path
                            d="M13.1301 22.19L11.5001 18.36C13.0701 17.78 14.5401 17 15.9001 16.09L13.1301 22.19ZM5.64006 12.5L1.81006 10.87L7.91006 8.10003C7.00006 9.46003 6.22006 10.93 5.64006 12.5ZM21.6101 2.39003C21.6101 2.39003 16.6601 0.269033 11.0001 5.93003C8.81006 8.12003 7.50006 10.53 6.65006 12.64C6.37006 13.39 6.56006 14.21 7.11006 14.77L9.24006 16.89C9.79006 17.45 10.6101 17.63 11.3601 17.35C13.5001 16.53 15.8801 15.19 18.0701 13C23.7301 7.34003 21.6101 2.39003 21.6101 2.39003ZM14.5401 9.46003C13.7601 8.68003 13.7601 7.41003 14.5401 6.63003C15.3201 5.85003 16.5901 5.85003 17.3701 6.63003C18.1401 7.41003 18.1501 8.68003 17.3701 9.46003C16.5901 10.24 15.3201 10.24 14.5401 9.46003ZM6.24006 22L9.88006 18.36C9.54006 18.27 9.21006 18.12 8.91006 17.91L4.83006 22H6.24006ZM2.00006 22H3.41006L8.18006 17.24L6.76006 15.83L2.00006 20.59V22ZM2.00006 19.17L6.09006 15.09C5.88006 14.79 5.73006 14.47 5.64006 14.12L2.00006 17.76V19.17Z"
                            fill="#aaadb8"
                        />
                        <!-- eslint-enable max-len -->
                    </svg>
                </div>

                <div :class="$style['modal__content__title']">
                    {{ $t('content-updated-modal-1') }}
                </div>

                <div :class="$style['modal__content__body']">
                    {{ $t('content-updated-modal-2') }}
                </div>

                <div :class="$style['modal__content__action']">
                    <button
                        type="button"
                        :class="$style['modal__content__action__button']"
                        @click="refreshPage()"
                    >
                        {{ $t('update') }} {{ seconds }} {{ $t('seconds-short') }}
                    </button>
                </div>
            </div>
        </Transition>
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .modal
        z-index: 99
        position: fixed
        display: flex
        align-items: center
        justify-content: center
        top: 0
        left: 0
        width: 100vw
        height: 100vh
        background: rgba(0, 0, 0, 0.45)
        transition: background-color 220ms var(--transition-default-ease)
        will-change: background-color
        cursor: pointer

        &--version-bottom
            align-items: flex-end

            &__content
                width: 100vw
                padding-bottom: 44px
                border-radius: 16px 16px 0 0

        &__content
            position: relative
            display: flex
            flex-direction: column
            align-items: center
            max-width: 364px
            padding: 32px
            background: #fff
            border-radius: 16px
            transform-origin: center center
            will-change: opacity, transfrom
            cursor: auto

            &__icon
                margin-bottom: 16px

            &__title
                margin-bottom: 16px
                font-size: 20px
                font-weight: 500
                line-height: 24px
                color: #000

            &__body
                margin-bottom: 32px
                font-size: 14px
                font-weight: 400
                line-height: 130%
                text-align: center
                color: #8a8f9e

            &__action
                display: flex
                width: 100%

                &__button
                    appearance: none
                    white-space: nowrap
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    flex: 1
                    vertical-align: middle
                    padding: 10px 24px
                    font-size: 13px
                    line-height: 20px
                    letter-spacing: 0.02em
                    font-style: normal
                    font-weight: 500
                    text-align: center
                    outline: none
                    border: none
                    background: var(--color-primary)
                    border-radius: 8px
                    user-select: none
                    transition: background-color var(--transition-default-duration-with-ease)
                    will-change: background-color
                    cursor: pointer

                    &:hover
                        background: #efcc1a

                    &:active
                        background: #efb31a
</style>

<style lang="sass">
    .modal-content-impulsive-transition
        &-enter-from
            opacity: 0
            transform: scale(1.1)

        &-enter-active
            pointer-events: none
            transition: opacity 220ms, transform 220ms cubic-bezier(0.25, 0.4, 0.55, 1.4) !important

        &-enter-to
            opacity: 1
            transform: scale(1)
</style>
