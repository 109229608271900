import type { UseAppFetchOptions } from '~/composables/useAppFetch'
import buildQuery from '~/helpers/fetch/buildQuery'

export default (options: UseAppFetchOptions): void => {
    !options.headers && (options.headers = {})

    options.headers['Accept-Language'] = useLang().language.value
    options.headers['X-Socket-ID'] = useBroadcaster().socketId.value

    options.query = buildQuery(options)
}
