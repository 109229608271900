import type { CookieOptions, CookieRef } from '#app/composables/cookie'
import { useCookie } from '#app/composables/cookie'

export const useAppCookie = <T = string | null | undefined>(
    name: string,
    options: CookieOptions<T> & {
        readonly?: false;
    } = {}
): CookieRef<T> => {
    const _isDevelopment = process.env.NODE_ENV === 'development'
    const oneYearInSeconds = 31536000

    options.maxAge = options.maxAge ?? oneYearInSeconds
    options.secure = options.secure ?? !_isDevelopment

    return useCookie<T>(name, options)
}
