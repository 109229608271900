import type { RuntimeConfig } from 'nuxt/schema'

export type UseLogOptions = {
    devOnly?: boolean
    warning?: boolean
    error?: boolean
    hint?: boolean
    disableTrace?: boolean
    disableTraceInServer?: boolean
}

export type UseLogExecutor = (message: any, place: string, options?: UseLogOptions) => void

const handler = (
    runtimeConfig: RuntimeConfig,
    message: any,
    place: string,
    options: UseLogOptions = { disableTraceInServer: true }
): void => {
    if (options.devOnly && runtimeConfig.public.isProd) {
        return
    }

    if (isUndefined(options.disableTraceInServer)) {
        options.disableTraceInServer = true
    }

    let style = 'font: Roboto; border-radius: 4px; padding: 3px 6px; width: 100%; '
    let type

    if (options.error) {
        type = 'error'
        style += 'color: #ff0000; font-weight: 700; border: 2px solid; background: rgba(255, 0, 0, 0.1)'
    } else if (options.warning) {
        type = 'warning'
        style += 'color: #fb8c00; font-weight: 400; border: 2px double; background: rgba(251, 140, 0, 0.1)'
    } else if (options.hint) {
        type = 'hint'
        style += 'color: #808080; font-weight: 400; font-style: italic; border: 1px dashed; background: rgba(128, 128, 128, 0.1)'
    } else {
        type = 'info'
        style += 'color: #00ffff; font-weight: 400; border: 1px solid; background: rgba(0, 255, 255, 0.1)'
    }

    /* eslint-disable no-console */
    if ([ 'number', 'boolean' ].includes(typeof message)) {
        message = String(message)
    }

    if (typeof message === 'string') {
        console.groupCollapsed(`%c(${ type }) [${ place }] ${ message }`, style)
    } else {
        console.groupCollapsed(`%c(${ type }) [${ place }] ▼`, style)

        console.dir(message)
    }

    if (!options.disableTrace && !(options.disableTraceInServer && process.server)) {
        console.trace(`%c(${ type }) [${ place }] log trace:`, style)
    }

    console.groupEnd()
    /* eslint-enable no-console */
}

export const useLog: UseLogExecutor = (...args) => {
    return handler(useRuntimeConfig(), ...args)
}

// Дивіться ~/docs/stores/README.md
export const useLogLazy = (): UseLogExecutor => {
    return handler.bind(this, useRuntimeConfig())
}
