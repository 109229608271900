import NotificationSuccess from '~/assets/sound/notification-success.ogg'
import NotificationInfo from '~/assets/sound/notification-info.ogg'
import NotificationError from '~/assets/sound/notification-error.ogg'
import NewChat from '~/assets/sound/new-chat.ogg'
import NewChatAccepted from '~/assets/sound/new-chat-accepted.ogg'
import NewArchiveChat from '~/assets/sound/new-archive-chat.ogg'
import NewTransferredChat from '~/assets/sound/new-transferred-chat.ogg'
import NewMessage from '~/assets/sound/new-message.ogg'

export type UseSound = {
    isPlaying: Ref<boolean>
    muted: Ref<boolean>
    play: VoidFunction
    stop: VoidFunction
}

type Sound =
    'notification-success'
    | 'notification-info'
    | 'notification-error'
    | 'new-chat'
    | 'new-chat-accepted'
    | 'new-archive-chat'
    | 'new-transferred-chat'
    | 'new-message'

export const useSound = (name: Sound): UseSound => {
    const muted = ref<boolean>(false)
    const isPlaying = ref<boolean>(false)

    const soundFiles: Record<Sound, any> = {
        'notification-success': NotificationSuccess,
        'notification-info': NotificationInfo,
        'notification-error': NotificationError,
        'new-chat': NewChat,
        'new-chat-accepted': NewChatAccepted,
        'new-archive-chat': NewArchiveChat,
        'new-transferred-chat': NewTransferredChat,
        'new-message': NewMessage
    }

    const sound = process.client && new Audio(soundFiles[name])

    const play = (): void => {
        if (!sound || isPlaying.value || muted.value) {
            return
        }

        sound.play()
            .then(() => (isPlaying.value = true))
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.warn(error)
            })
    }

    const stop = (): void => {
        if (!sound || !isPlaying.value) {
            return
        }

        sound.pause()

        sound.currentTime = 0

        isPlaying.value = false
    }

    if (sound) {
        onEvent(sound, 'ended', stop)
    }

    return {
        isPlaying,
        muted,
        play,
        stop
    }
}
