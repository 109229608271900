import type { SearchParameters } from 'ofetch'
import type { AnyObj } from '~/ts/types/common'

export const buildSearchParameters = (queryObject: AnyObj, prefix = ''): SearchParameters => {
    return Object.entries(queryObject)
        .reduce((result, [ key, value ]) => {
            const newKey = prefix ? `${ prefix }[${ key }]` : key

            if (isObject(value)) {
                const nestedParams = buildSearchParameters(value, newKey)

                Object.assign(result, nestedParams)
            } else if (isArray(value)) {
                result[newKey + '[]'] = value
            } else if (!isEmpty(value, { primitive: true })) {
                result[newKey] = value
            }

            return result
        }, {})
}
