import type * as cardTypes from '~/ts/types/card'

export default {
    all({ page = 1, perPage = undefined }: cardTypes.CardAll = {}) {
        return useAppFetch<{ items: cardTypes.Card[] }>('/user/{userId}/card', {
            params: {
                page,
                'per-page': perPage
            }
        })
    },
    add() {
        return useAppFetch<{ html: string }>('/user/{userId}/card', {
            method: 'post'
        })
    },
    markAsPrimary({ id }: cardTypes.CardMarkAsPrimary) {
        return useAppFetch<cardTypes.Card>(`/user/{userId}/card/${ id }/as-primary`, {
            method: 'post'
        })
    },
    delete({ id }: cardTypes.CardDelete) {
        return useAppFetch(`/user/{userId}/card/${ id }`, {
            method: 'delete'
        })
    }
}
