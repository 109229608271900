import type { RouteLocation } from 'vue-router'

export const TECHNICAL_PAGE_NAME_BY_ROUTE_NAME = {
    'language-auth-change-password-token': 'Авторизація, зміна паролю',
    'language-auth-confirm-token': 'Авторизація, підтвердження пошти (редірект)',
    'language-auth-create-password-token': 'Авторизація, створення паролю',
    'language-auth-invite-token': 'Авторизація, підтвердження запрошення (редірект)',
    'language-auth-sign-in': 'Авторизація, вхід',
    'language-auth-sign-up': 'Авторизація, реєстрація',
    'language-auth-sign-up-step': 'Авторизація, реєстрація, крок 0 (батьківська, редірект)',
    'language-auth-sign-up-step-1': 'Авторизація, реєстрація, крок 1',
    'language-auth-sign-up-step-2': 'Авторизація, реєстрація, крок 2',
    'language-auth-sign-up-step-3': 'Авторизація, реєстрація, крок 3',
    'language-auth-sign-up-step-4': 'Авторизація, реєстрація, крок 4',
    'language-auth-reset-password-token': 'Авторизація, відновлення паролю',
    'language-auth-reset-password-request': 'Авторизація, запит на відновлення паролю',
    // eslint-disable-next-line quote-props
    'index': 'Початкова (редірект)',
    'p-pid-billing': 'Підписка',
    'p-pid-chat': 'Чат (батьківська, редірект)',
    'p-pid-chat-kind': 'Чат, вкладка{chat_tab}',
    'p-pid-chat-kind-cid': 'Чат, поточний чат',
    'p-pid-knowledge-base': 'База знань',
    'p-pid-setting': 'Налаштування (батьківська, редірект)',
    'p-pid-setting-account': 'Налаштування, аккаунт',
    'p-pid-setting-channel': 'Налаштування, канали звʼязку',
    'p-pid-setting-channel-id-configure': 'Налаштування, канали звʼязку (батьківська, редірект)',
    'p-pid-setting-channel-id-configure-telegram-basic-settings': 'Налаштування, канали звʼязку, Telegram, основні',
    'p-pid-setting-channel-id-configure-telegram-operators': 'Налаштування, канали звʼязку, Telegram, оператори',
    'p-pid-setting-channel-id-configure-viber-basic-settings': 'Налаштування, канали звʼязку, Viber, основні',
    'p-pid-setting-channel-id-configure-viber-operators': 'Налаштування, канали звʼязку, Viber, оператори',
    'p-pid-setting-channel-id-configure-widget-basic-settings': 'Налаштування, канали звʼязку, Widget, основні',
    'p-pid-setting-channel-id-configure-widget-code-script': 'Налаштування, канали звʼязку, Widget, скрипт',
    'p-pid-setting-channel-id-configure-widget-operators': 'Налаштування, канали звʼязку, Widget, оператори',
    'p-pid-setting-channel-id-configure-widget-personalization': 'Налаштування, канали звʼязку, Widget, персоналізація',
    'p-pid-setting-channel-id-configure-widget-contacts': 'Налаштування, канали звʼязку, Widget, контакти',
    'p-pid-setting-channel-connect-telegram': 'Налаштування, канали звʼязку, Telegram, підключення',
    'p-pid-setting-channel-connect-viber': 'Налаштування, канали звʼязку, Viber, підключення',
    'p-pid-setting-operator': 'Налаштування, оператори',
    'p-pid-setting-operator-id-edit': 'Налаштування, оператори, редагування (батьківська, редірект)',
    'p-pid-setting-operator-id-edit-settings': 'Налаштування, оператори, редагування, основні',
    'p-pid-setting-operator-id-edit-work-schedule': 'Налаштування, оператори, редагування, графік роботи',
    'p-pid-setting-operator-create': 'Налаштування, оператори, створення',
    'p-pid-setting-project': 'Налаштування, проєкти',
    'p-pid-setting-project-id-edit': 'Налаштування, проєкти, редагування',
    'p-pid-setting-project-create': 'Налаштування, проєкти, створення',
    'p-pid-setting-quick-answer': 'Налаштування, швидкі відповіді',
    'p-pid-setting-quick-answer-id-edit': 'Налаштування, швидкі відповіді, редагування',
    'p-pid-setting-quick-answer-create': 'Налаштування, швидкі відповіді, створення'
}

export const getPageTechnicalName = (route: RouteLocation): string => {
    const name: string = TECHNICAL_PAGE_NAME_BY_ROUTE_NAME[route.name]

    if (!name) {
        return `[${ route.name as string }]`
    }

    if (name.includes('{chat_tab}')) {
        const { t } = useLang()

        const tabName = t('chat-tab-item-' + route.params.kind).toLowerCase()

        return name.replace('{chat_tab}', ', ' + tabName)
    }

    return name
}
