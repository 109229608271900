import type * as knowledgeBaseTypes from '~/ts/types/knowledge-base'

export default {
    tree({ contentLanguage, onlyPublished }: knowledgeBaseTypes.KBTree) {
        return useAppFetch<knowledgeBaseTypes.KBTreeItem>('/project/{projectId}/kb/tree', {
            params: {
                onlyPublished,
                content_language: contentLanguage
            }
        })
    },
    updateTree({ id, data }: knowledgeBaseTypes.KBTreeUpdate) {
        return useAppFetch<knowledgeBaseTypes.KBTreeItem>(`/project/{projectId}/kb/${ id }`, {
            method: 'put',
            body: data
        })
    },
    createCategory({ data, contentLanguage }: knowledgeBaseTypes.KBCategoryCreate) {
        return useAppFetch<knowledgeBaseTypes.KBCategory>('/project/{projectId}/kb/category', {
            method: 'post',
            params: {
                content_language: contentLanguage
            },
            body: data
        })
    },
    updateCategory({ id, data, contentLanguage }: knowledgeBaseTypes.KBCategoryUpdate) {
        return useAppFetch<knowledgeBaseTypes.KBCategory>(`/project/{projectId}/kb/category/${ id }`, {
            method: 'put',
            params: {
                content_language: contentLanguage
            },
            body: data
        })
    },
    deleteCategory({ id }: knowledgeBaseTypes.KBItemDelete) {
        return useAppFetch(`/project/{projectId}/kb/category/${ id }`, {
            method: 'delete'
        })
    },
    oneArticle({ id, contentLanguage }: knowledgeBaseTypes.KBArticleOne) {
        return useAppFetch<knowledgeBaseTypes.KBArticle>(`/project/{projectId}/kb/article/${ id }`, {
            params: {
                content_language: contentLanguage
            }
        })
    },
    createArticle({ data, contentLanguage }: knowledgeBaseTypes.KBArticleCreate) {
        return useAppFetch<knowledgeBaseTypes.KBArticle>('/project/{projectId}/kb/article', {
            method: 'post',
            params: {
                content_language: contentLanguage
            },
            body: data
        })
    },
    updateArticle({ id, data, contentLanguage }: knowledgeBaseTypes.KBArticleUpdate) {
        return useAppFetch<knowledgeBaseTypes.KBArticle>(`/project/{projectId}/kb/article/${ id }`, {
            method: 'put',
            params: {
                content_language: contentLanguage
            },
            body: data
        })
    },
    deleteArticle({ id }: knowledgeBaseTypes.KBItemDelete) {
        return useAppFetch(`/project/{projectId}/kb/article/${ id }`, {
            method: 'delete'
        })
    },
    moveItem({ id, data }: knowledgeBaseTypes.KBItemMove) {
        return useAppFetch(`/project/{projectId}/kb/move/${ id }`, {
            method: 'put',
            params: data
        })
    },
    articleFeedbacks({ id, query }: knowledgeBaseTypes.KBArticleFeedbackAll) {
        return useAppFetch<{
            items: knowledgeBaseTypes.KBArticleFeedbackItem[]
        }>(`/project/{projectId}/kb/article/${ id }/feedback`, {
            params: { id },
            query
        })
    },
    deleteArticleFeedback({ articleId, feedbackId }: knowledgeBaseTypes.KBArticleFeedbackDelete) {
        return useAppFetch(`/project/{projectId}/kb/article/${ articleId }/feedback/${ feedbackId }`, {
            method: 'delete'
        })
    }
}
