import { useLanguageStore } from '~/stores/language'

export type LangT = (key: string, ...args: string[]) => string
export type LangTC = (key: string, count: number) => string

export const langToolT = (
    languageStore: ReturnType<typeof useLanguageStore>,
    locales: Record<string, string>,
    key: string,
    ...args: any[]
) => {
    const transKey: string = locales[languageStore.language]?.[key]
        || locales[languageStore.defaultLanguage]?.[key]

    if (!transKey) {
        return key
    }

    if (args?.length) {
        return args.reduce<string>((result, value, index) => {
            return result.replace(`$${ index }$`, value)
        }, transKey)
    }

    return transKey
}

export const langToolTC = (
    languageStore: ReturnType<typeof useLanguageStore>,
    locales: Record<string, string>,
    key: string,
    count: number
) => {
    const transKey = locales[languageStore.language]?.[key]
        || locales[languageStore.defaultLanguage]?.[key]

    if (!transKey) {
        return key
    }

    const transKeyParts = transKey.split(' | ')

    const pluralize = (choice, choicesLength): string => {
        if (choice === 0) {
            return transKeyParts[0]
        }

        const teen = choice > 10 && choice < 20
        const endsWithOne = choice % 10 === 1

        if (choicesLength < 4) {
            return transKeyParts[(!teen && endsWithOne) ? 1 : 2]
        }

        if (!teen && endsWithOne) {
            return transKeyParts[1]
        }

        if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return transKeyParts[2]
        }

        return transKeyParts[(choicesLength < 4) ? 2 : 3]
    }

    const replaceValue = (string: string): string => string.replace(/\$VALUE\$/g, String(count))

    return replaceValue(pluralize(count, transKeyParts.length))
}
