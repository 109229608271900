export const useMounted = (): Ref<boolean> => {
    const isMounted = ref<boolean>(false)

    if (getCurrentInstance()) {
        onMounted(() => {
            isMounted.value = true
        })
    }

    return isMounted
}
