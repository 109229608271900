type UseTabActivity = {
    isTabActive: Ref<boolean>
    onTabActive: (fn: Function) => VoidFunction
    onTabInactive: (fn: Function) => VoidFunction
}

type UseTabActiveOptions = {
    listen?: boolean
}

export const useTabActivity = (options: UseTabActiveOptions = {}): UseTabActivity => {
    const {
        listen = true
    } = options

    const { window, document } = getClientContext()

    const isTabActive = ref<boolean>(false)

    if (!window) {
        return {
            isTabActive,
            onTabActive: () => noop,
            onTabInactive: () => noop
        }
    }

    const hooks = {
        onTabActive: createEventHook(),
        onTabInactive: createEventHook()
    }

    const handler = (): void => {
        isTabActive.value = document.visibilityState === 'visible'

        listen && hooks[isTabActive.value ? 'onTabActive' : 'onTabInactive'].trigger()
    }

    handler()

    if (listen) {
        onEvent(window, 'visibilitychange', handler)
    }

    return {
        isTabActive,
        onTabActive: hooks.onTabActive.on,
        onTabInactive: hooks.onTabInactive.on
    }
}
