import { useUserStore } from '~/stores/user'

export default (pid?: number, route = useRoute()): ReturnType<typeof navigateTo> => {
    const userStore = useUserStore()

    if (!userStore.isAuthenticated) {
        /*
        * Якщо відбувається відкриття індексної сторінки,
        * то у плагінах (наприклад, handshake) є перевірка, при котрій скрипт плагіну не буде виконано через очікування перенаправлення
        * При перенаправленні в middleware, query та інші деталі маршруту, які були задані як початкові, можуть бути втрачені
        */
        return navigateTo({
            name: 'language-auth-sign-in',
            query: route.query
        })
    }

    if (!userStore.isRegistrationCompleted) {
        return navigateTo({ name: 'language-auth-sign-up-step-' + userStore.user.signup_step })
    }

    if (!pid) {
        pid = userStore.currentProject.id
    }

    if (!userStore.isOperatorEnabled) {
        navigateTo({
            name: 'p-pid-inactive-operator',
            params: { pid }
        })
    }

    if ('bank-card-added' in route.query) {
        return navigateTo({
            name: 'p-pid-billing',
            params: { pid },
            query: route.query
        })
    }

    return navigateTo({
        name: 'p-pid-chat',
        params: { pid }
    })
}
