import { useUserStore } from '~/stores/user'

export default (router = useRouter(), userStore?: ReturnType<typeof useUserStore>): boolean => {
    const routeFullPath = router.currentRoute.value.fullPath
    const routeMeta = router.currentRoute.value.meta

    // Чи планується перенаправлення
    if ((routeFullPath === '/') || routeFullPath.startsWith('/?')) {
        return false
    }

    // Чи це зовнішня сторінка
    if (routeFullPath.startsWith('/external/')) {
        return false
    }

    if (!userStore) {
        userStore = useUserStore()
    }

    // Чи це сторінка авторизації при авторизованому користувачі
    if (userStore.isAuthenticated && (routeMeta.middleware === 'guest')) {
        return false
    }

    return true
}
