import type { Language } from '~/ts/types/language'
import type { LangT } from '~/helpers/languageTools'
import { useLanguageStore } from '~/stores/language'
import { useUserStore } from '~/stores/user'
import { langToolT } from '~/helpers/languageTools'
import pluginCheckContext from '~/helpers/pluginCheckContext'
import uk from '~/locales/uk.json'
import en from '~/locales/en.json'
import ru from '~/locales/ru.json'

type Injections = {
    t: LangT
}

declare module '#app' {
    interface NuxtApp {
        $t: Injections['t']
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $t: Injections['t']
    }
}

export default defineNuxtPlugin((nuxtApp): { provide: Injections } => {
    const userStore = useUserStore()

    if (useError().value || !pluginCheckContext(nuxtApp.$router as any, userStore)) {
        return { provide: { t: (key: string): string => key } }
    }

    const languageStore = useLanguageStore()
    const { isSupportedLanguage, setLanguage, onLanguageChange } = languageStore

    if (process.server) {
        const { currentRoute } = useRouter()
        const userLanguage = userStore.user.language
        const routeLang = String(currentRoute.value.params.language || '') as Language
        const cookieLang = useAppCookie<Language>('language').value as Language
        const preferredLanguage = userLanguage || cookieLang || routeLang

        if (preferredLanguage && isSupportedLanguage(preferredLanguage)) {
            if (languageStore.language !== preferredLanguage) {
                setLanguage(preferredLanguage)
            }

            if (!userStore.isAuthenticated && (routeLang !== preferredLanguage)) {
                if (preferredLanguage === languageStore.defaultLanguage) {
                    if (routeLang) {
                        navigateTo({ params: { language: '' } })
                    }
                } else {
                    navigateTo({ params: { language: preferredLanguage } })
                }
            }
        }
    } else {
        userStore.onIn(() => {
            const userLanguage = userStore.user.language

            if (userLanguage && (languageStore.language !== userLanguage)) {
                setLanguage(userLanguage)
            }
        })

        onLanguageChange((value) => {
            if (userStore.isAuthenticated) {
                userStore.update({ language: value })
            }
        })
    }

    const locales = { uk, en, ru }

    return {
        provide: {
            t: langToolT.bind(this, languageStore, locales)
        }
    }
})
