import pluginCheckContext from '~/helpers/pluginCheckContext'

export default defineNuxtPlugin({
    name: 'gtag',
    parallel: true,
    hooks: {
        'app:created'(): void {
            const router = useRouter()

            if (useError().value || !pluginCheckContext(router)) {
                return
            }

            const { appName, gtmId, gaMeasurementId } = useRuntimeConfig().public

            if (!gtmId) {
                return
            }

            window.dataLayer = window.dataLayer || []

            window.gtag = (...args) => {
                window.dataLayer.push(args)
            }

            window.gtag('js', new Date())
            window.gtag('config', gaMeasurementId, { app_name: appName, send_page_view: false })

            useHead({
                script: [
                    {
                        innerHTML: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','${ gtmId }');
                        `
                    },
                    {
                        src: 'https://www.googletagmanager.com/gtag/js?id=' + gaMeasurementId,
                        async: true
                    }
                ]
            })

            router.isReady()
                .then(() => gaPageView(router.currentRoute.value))

            router.afterEach((to, from) => {
                if (to.path === from.path) {
                    return
                }

                gaPageView(to)
            })
        }
    }
})
