import type * as chatTypes from '~/ts/types/chat'
import { ChatKindEnum } from '~/ts/enums/chat'

export default {
    chats({ kind, page, perPage = APP_MAX_PER_PAGE, query = undefined }: chatTypes.Chats) {
        const urlPaths = {
            [ChatKindEnum.New]: '',
            [ChatKindEnum.My]: '/my',
            [ChatKindEnum.Archive]: '/archive'
        }

        return useAppFetch<{ items: chatTypes.Chat[] }>(`/project/{projectId}/chat${ urlPaths[kind] }`, {
            params: {
                page,
                'per-page': perPage
            },
            query
        })
    },
    chatAccept({ id, data }: chatTypes.ChatAccept) {
        return useAppFetch<chatTypes.Chat>(`/project/{projectId}/chat/${ id }/accept`, {
            method: 'post',
            body: data
        })
    },
    chatReject({ id, data }: chatTypes.ChatReject) {
        return useAppFetch<chatTypes.Chat>(`/project/{projectId}/chat/${ id }/reject`, {
            method: 'post',
            body: data
        })
    },
    chatClose({ id }: chatTypes.ChatClose) {
        return useAppFetch<chatTypes.Chat>(`/project/{projectId}/chat/${ id }/close`, {
            method: 'post'
        })
    },
    chatRestore({ id, data }: chatTypes.ChatRestore) {
        return useAppFetch<chatTypes.Chat>(`/project/{projectId}/chat/${ id }/reopen`, {
            method: 'post',
            body: data
        })
    },
    chatTransfer({ id, data }: chatTypes.ChatTransfer) {
        return useAppFetch<chatTypes.Chat>(`/project/{projectId}/chat/${ id }/transfer`, {
            method: 'post',
            body: data
        })
    },
    chatMessages({ chatId, beforeId = undefined }: chatTypes.ChatMessages) {
        return useAppFetch<{ items: chatTypes.ChatMessage[] }>(`/project/{projectId}/chat/${ chatId }/message`, {
            params: {
                before_id: beforeId,
                'per-page': CHAT_MESSAGES_PER_PAGE
            }
        })
    },
    chatMessageSendText({ chatId, data }: chatTypes.ChatMessageSendText) {
        return useAppFetch<chatTypes.ChatMessage>(`/project/{projectId}/chat/${ chatId }/message/send-text`, {
            method: 'post',
            body: data
        })
    },
    chatMessageSendImage({ chatId, data }: chatTypes.ChatMessageSendImage) {
        return useAppFetch<chatTypes.ChatMessage>(`/project/{projectId}/chat/${ chatId }/message/send-image`, {
            method: 'post',
            body: data
        })
    },
    chatMessageSendGif({ chatId, data }: chatTypes.ChatMessageSendGif) {
        return useAppFetch<chatTypes.ChatMessage>(`/project/{projectId}/chat/${ chatId }/message/send-gif`, {
            method: 'post',
            body: data
        })
    },
    chatMessageSendArticle({ chatId, data }: chatTypes.ChatMessageSendArticle) {
        return useAppFetch<chatTypes.ChatMessage>(`/project/{projectId}/chat/${ chatId }/message/send-kb-article`, {
            method: 'post',
            body: data
        })
    },
    chatMessageRead({ chatId, messageId }: chatTypes.ChatMessageRead) {
        return useAppFetch<{
            count: number,
            last_read: string
        }>(`/project/{projectId}/chat/${ chatId }/message/read/${ messageId }`, {
            method: 'put'
        })
    },
    chatMessageUpdate({ chatId, messageId, data }: chatTypes.ChatMessageUpdate) {
        return useAppFetch<chatTypes.ChatMessage>(`/project/{projectId}/chat/${ chatId }/message/${ messageId }`, {
            method: 'put',
            body: data
        })
    },
    chatMessageDelete({ chatId, messageId }: chatTypes.ChatMessageDelete) {
        return useAppFetch(`/project/{projectId}/chat/${ chatId }/message/${ messageId }`, {
            method: 'delete'
        })
    },
    gifFavorite() {
        return useAppFetch<chatTypes.ChatGif[]>('/gif/favorite')
    },
    gifSearch({ search }: chatTypes.ChatGifSearch) {
        return useAppFetch<chatTypes.ChatGif[]>('/gif/search', {
            params: { q: search }
        })
    }
}
