export default (request: string, userStore: ReturnType<typeof useUserStore>): string => {
    if (request.includes('{projectId}')) {
        request = request.replace('{projectId}', String(userStore.currentProject.id))
    }

    if (request.includes('{operatorId}')) {
        request = request.replace('{operatorId}', String(userStore.currentOperator.id))
    }

    if (request.includes('{userId}')) {
        request = request.replace('{userId}', String(userStore.user.id))
    }

    return request
}
