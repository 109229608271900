import type { FetchError } from 'ofetch'
import type { UseFetchOptions } from '#app'
import { useUserStore } from '~/stores/user'
import createFetchKey from '~/helpers/fetch/createFetchKey'
import fillConfigOptions from '~/helpers/fetch/fillConfigOptions'
import fillRequestOptions from '~/helpers/fetch/fillRequestOptions'
import replaceRequestVariables from '~/helpers/fetch/replaceRequestVariables'
import setAuthToken from '~/helpers/fetch/setAuthToken'
import refreshAuthToken from '~/helpers/fetch/refreshAuthToken'
import updateAuthTokenFromServer from '~/helpers/fetch/updateAuthTokenFromServer'
import pushErrorNotify from '~/helpers/fetch/pushErrorNotify'

export type UseAppFetchOptions = UseFetchOptions<any> & {
    skipAuthTokenProcessing?: boolean
    preventLogout?: boolean
}

export type UseAppFetch<DataT> = ReturnType<typeof useAsyncData<DataT, FetchError>>

export const useAppFetch = <T>(request: string, options: UseAppFetchOptions = {}): UseAppFetch<T> => {
    if (useError().value) {
        useLog('Request intercepted due to error', 'useAppFetch', { warning: true })

        return useAsyncData(createFetchKey(request, options), () => new Promise(noop))
    }

    // TODO можливо якщо прослуховувати то це не буде заважати запиту на телефоні
    const { isOnline } = useNetwork({ listen: false })

    if (!isOnline.value) {
        useLog('Request intercepted due to lack of internet', 'useAppFetch', { warning: true })

        return useAsyncData(createFetchKey(request, options), () => new Promise(noop))
    }

    const userStore = useUserStore()
    const token = useAppCookie<string>('token')

    request = replaceRequestVariables(request, userStore)

    updateAuthTokenFromServer(token)

    fillConfigOptions(options, request)

    return useFetch(request, {
        ...options,
        async onRequest({ options: opts }) {
            fillRequestOptions(opts as UseAppFetchOptions)

            await setAuthToken({ options: opts as UseAppFetchOptions, token, userStore })
        },
        onRequestError({ request, error }) {
            pushErrorNotify(error)

            error.message = request + ': ' + error?.message || '-'

            useLog(error, 'onRequestError', {
                warning: true,
                devOnly: true
            })
        },
        async onResponseError({ response, options: opts, error }) {
            if (response.status === 401) {
                try {
                    await refreshAuthToken({ options: opts as any, token, userStore })
                } catch (error) {
                }
            } else {
                if (isObject(typeof response._data)) {
                    response._data.requestOptions = opts
                    response._data.errorMsg = error?.message || ''
                }

                pushErrorNotify(error || { status: response.status } as any)
            }
        }
    })
}
