export type CreateLockContext<T = any> = {
    pending: boolean
    queue: ReturnType<typeof createEventHook>
    request: () => Promise<T>
}

export const createLock = <T = any>(fn: Function): CreateLockContext<T> => ({
    pending: false,
    queue: createEventHook(),
    request(): Promise<T> {
        return new Promise((resolve) => {
            if (this.pending) {
                this.queue.on(resolve)

                return
            }

            this.pending = true

            ;(async () => {
                const payload = await fn()

                resolve(payload)

                await this.queue.triggerAndOff(payload)

                this.pending = false
            })()
        })
    }
})
