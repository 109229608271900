import type * as subscriptionTypes from '~/ts/types/subscription'

export default {
    one({ id }: subscriptionTypes.SubscriptionOne) {
        return useAppFetch<subscriptionTypes.Subscription>(`/project/{projectId}/subscription/${ id }`)
    },
    cancel({ id }: subscriptionTypes.SubscriptionCancel) {
        return useAppFetch<subscriptionTypes.Subscription>(`/project/{projectId}/subscription/${ id }/cancel`, {
            method: 'post'
        })
    },
    renew({ id }: subscriptionTypes.SubscriptionRenew) {
        return useAppFetch<subscriptionTypes.Subscription>(`/project/{projectId}/subscription/${ id }/renew`, {
            method: 'post'
        })
    }
}
