import type * as visitorTypes from '~/ts/types/visitor'

export default {
    update({ id, data }: visitorTypes.VisitorUpdate) {
        return useAppFetch<visitorTypes.Visitor>(`/project/{projectId}/visitor/${ id }`, {
            method: 'put',
            body: data
        })
    },
    createContact({ visitorId, data }: visitorTypes.VisitorCreateContact) {
        return useAppFetch<visitorTypes.VisitorContact>(`/project/{projectId}/visitor/${ visitorId }/contact `, {
            method: 'post',
            body: data
        })
    },
    updateContact({ visitorId, id, data }: visitorTypes.VisitorUpdateContact) {
        return useAppFetch<visitorTypes.VisitorContact>(`/project/{projectId}/visitor/${ visitorId }/contact/${ id }`, {
            method: 'put',
            body: data
        })
    },
    deleteContact({ visitorId, id }: visitorTypes.VisitorDeleteContact) {
        return useAppFetch(`/project/{projectId}/visitor/${ visitorId }/contact/${ id }`, {
            method: 'delete'
        })
    }
}
