import type { User } from '~/ts/types/user'

export default {
    link({ token }: { token: string }) {
        return useAppFetch<User['google']>('/google/link', {
            method: 'post',
            body: { token }
        })
    },
    unlink() {
        return useAppFetch('/google/unlink', {
            method: 'post'
        })
    }
}
