export type EventHook<T = any> = {
    on: (fn: ((payload?: T) => void) | Function) => VoidFunction
    onOnce: (fn: ((payload?: T) => void) | Function) => VoidFunction
    off: (fn: VoidFunction) => void
    trigger: (...payload: T[]) => Promise<unknown[]>
    triggerAndOff: (...payload: T[]) => Promise<unknown[]>
    clear: VoidFunction
    getSize: () => number
}

export const createEventHook = <T = any>(): EventHook<T> => {
    const fns = new Set<Function>()

    const off = (fn: Function): void => {
        fns.delete(fn)
    }

    const on = (fn: Function): VoidFunction => {
        fns.add(fn)

        tryOnScopeDispose(() => off(fn))

        return () => off(fn)
    }

    const onOnce = (fn: Function): VoidFunction => {
        const cb = async () => {
            off(cb)

            await fn()
        }

        fns.add(cb)

        tryOnScopeDispose(() => off(cb))

        return () => off(cb)
    }

    const trigger = (...args): Promise<unknown[]> => {
        return Promise.all(Array.from(fns).map(fn => fn(...args)))
    }

    const triggerAndOff = (...args): Promise<unknown[]> => {
        return Promise.all(Array.from(fns).map((fn) => {
            off(fn)

            return fn(...args)
        }))
    }

    const clear = (): void => fns.clear()

    const getSize = () => fns.size

    return {
        on,
        onOnce,
        off,
        trigger,
        triggerAndOff,
        clear,
        getSize
    }
}
