import type { IsEmptyOptions } from '~/utils/isEmpty'

type CacheKey = string | number

type UseCacheFn<T> = () => T

type UseScopeCache = {
    computedScopeCache: <T = any>(key: CacheKey, fn: () => T, isEmptyOptions?: IsEmptyOptions) => T
    removeScopeCache: (key: CacheKey) => boolean
    clearScopeCache: VoidFunction
}

export const appCache = new Map<CacheKey, any>()

export const useCacheFn = <T>(key: CacheKey, fn: Function, isEmptyOptions?: IsEmptyOptions): UseCacheFn<T> => {
    return (...args) => {
        const cached = appCache.get(key)

        if (!isEmpty(cached, isEmptyOptions)) {
            return cached
        }

        const newValue = fn(...args)

        appCache.set(key, newValue)

        return newValue
    }
}

export const useScopeCache = (): UseScopeCache => {
    const scopeCache = new Map<CacheKey, any>()

    const computedScopeCache = <T = any>(key: CacheKey, fn: Function, isEmptyOptions?: IsEmptyOptions): T => {
        let data = scopeCache.get(key)

        if (isEmpty(data, isEmptyOptions)) {
            data = fn()

            scopeCache.set(key, data)
        }

        return data
    }

    const removeScopeCache = (key: CacheKey): boolean => scopeCache.delete(key)

    const clearScopeCache = (): void => scopeCache.clear()

    tryOnScopeDispose(() => clearScopeCache())

    return {
        computedScopeCache,
        removeScopeCache,
        clearScopeCache
    }
}
