/*
* Client side only
* Реальні значення встановлюються з обʼєкту Window під час хуку onMounted
*/

import * as screenSizes from '~/constants/screen'

export type UseWindowSize = {
    width: Ref<number>
    height: Ref<number>
    mobile: Ref<boolean>
    tablet: Ref<boolean>
    maxTablet: Ref<boolean>
    desktop: Ref<boolean>
    maxDesktop: Ref<boolean>
    onMobileChange: (cb: (value: boolean) => any) => Function
    onTabletChange: (cb: (value: boolean) => any) => Function
    onMaxTabletChange: (cb: (value: boolean) => any) => Function
    onDesktopChange: (cb: (value: boolean) => any) => Function
    onMaxDesktopChange: (cb: (value: boolean) => any) => Function
}

type Options = {
    initialWidth?: number
    initialHeight?: number
    listenOrientation?: boolean
    includeScrollbar?: boolean
}

export const useWindowSize = (options: Options = {}): UseWindowSize => {
    const { window, document } = getClientContext()

    const {
        initialWidth = 0,
        initialHeight = 0,
        listenOrientation = true,
        includeScrollbar = true
    } = options

    const width = ref<number>(initialWidth)
    const height = ref<number>(initialHeight)

    const getMobileValue = (): boolean => {
        return !!width.value
            && (width.value >= screenSizes.SCREEN_MOBILE_SIZE)
    }

    const getTabletValue = (): boolean => {
        return !!width.value
            && (width.value >= screenSizes.SCREEN_TABLET_SIZE)
    }

    const getMaxTabletValue = (): boolean => {
        return !!width.value
            && (width.value < screenSizes.SCREEN_TABLET_SIZE)
    }

    const getDesktopValue = (): boolean => {
        return !!width.value
            && (width.value >= screenSizes.SCREEN_DESKTOP_SIZE)
    }

    const getMaxDesktopValue = (): boolean => {
        return !!width.value
            && (width.value < screenSizes.SCREEN_DESKTOP_SIZE)
    }

    const mobile = ref<boolean>(undefined)
    const tablet = ref<boolean>(undefined)
    const maxTablet = ref<boolean>(undefined)
    const desktop = ref<boolean>(undefined)
    const maxDesktop = ref<boolean>(undefined)

    if (!window) {
        return {
            width,
            height,
            mobile,
            tablet,
            maxTablet,
            desktop,
            maxDesktop,
            onMobileChange: () => noop,
            onTabletChange: () => noop,
            onMaxTabletChange: () => noop,
            onDesktopChange: () => noop,
            onMaxDesktopChange: () => noop
        }
    }

    const hooks = {
        onMobileChange: createEventHook(),
        onTabletChange: createEventHook(),
        onMaxTabletChange: createEventHook(),
        onDesktopChange: createEventHook(),
        onMaxDesktopChange: createEventHook()
    }

    const update = throttleFn(() => {
        if (!window) {
            return
        }

        let newWidth: number
        let newHeight: number

        if (includeScrollbar) {
            newWidth = window.innerWidth
            newHeight = window.innerHeight
        } else {
            newWidth = document.documentElement.clientWidth
            newHeight = document.documentElement.clientHeight
        }

        width.value = newWidth
        height.value = newHeight

        const newMobile = getMobileValue()
        const newTablet = getTabletValue()
        const newMaxTablet = getMaxTabletValue()
        const newDesktop = getDesktopValue()
        const newMaxDesktop = getMaxDesktopValue()

        if (mobile.value !== newMobile) {
            hooks.onMobileChange.trigger(newMobile)
        }

        if (tablet.value !== newTablet) {
            hooks.onTabletChange.trigger(newTablet)
        }

        if (maxTablet.value !== newMaxTablet) {
            hooks.onMaxTabletChange.trigger(newMaxTablet)
        }

        if (desktop.value !== newDesktop) {
            hooks.onDesktopChange.trigger(newDesktop)
        }

        if (maxDesktop.value !== newMaxDesktop) {
            hooks.onMaxDesktopChange.trigger(newMaxDesktop)
        }

        mobile.value = newMobile
        tablet.value = newTablet
        maxTablet.value = newMaxTablet
        desktop.value = newDesktop
        maxDesktop.value = newMaxDesktop
    }, 200)

    tryOnMounted(() => {
        update()

        onEvent(window, 'resize', update, { passive: true })

        if (listenOrientation) {
            const matches = useMediaQuery('(orientation: portrait)')

            watch(matches, update)
        }
    })

    return {
        width,
        height,
        mobile,
        tablet,
        maxTablet,
        desktop,
        maxDesktop,
        onMobileChange: hooks.onMobileChange.on,
        onTabletChange: hooks.onTabletChange.on,
        onMaxTabletChange: hooks.onMaxTabletChange.on,
        onDesktopChange: hooks.onDesktopChange.on,
        onMaxDesktopChange: hooks.onMaxDesktopChange.on
    }
}
