import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src1727701116/src/spilky-client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src1727701116/src/spilky-client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src1727701116/src/spilky-client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src1727701116/src/spilky-client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src1727701116/src/spilky-client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src1727701116/src/spilky-client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1727701116/src/spilky-client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src1727701116/src/spilky-client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _02_localization_mTv8dH3OTH from "/codebuild/output/src1727701116/src/spilky-client/plugins/02.localization.ts";
import _03_setup_JeonwQUkuW from "/codebuild/output/src1727701116/src/spilky-client/plugins/03.setup.ts";
import _04_popupManager_2RFfGIF6ES from "/codebuild/output/src1727701116/src/spilky-client/plugins/04.popupManager.ts";
import _05_sentry_client_aLToQO08jh from "/codebuild/output/src1727701116/src/spilky-client/plugins/05.sentry.client.ts";
import _06_handleChunkError_client_roonkBFtTI from "/codebuild/output/src1727701116/src/spilky-client/plugins/06.handleChunkError.client.ts";
import _07_gtag_client_2efLxluVLI from "/codebuild/output/src1727701116/src/spilky-client/plugins/07.gtag.client.ts";
import _08_hideLoader_client_3zoiITktUG from "/codebuild/output/src1727701116/src/spilky-client/plugins/08.hideLoader.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  _02_localization_mTv8dH3OTH,
  _03_setup_JeonwQUkuW,
  _04_popupManager_2RFfGIF6ES,
  _05_sentry_client_aLToQO08jh,
  _06_handleChunkError_client_roonkBFtTI,
  _07_gtag_client_2efLxluVLI,
  _08_hideLoader_client_3zoiITktUG
]