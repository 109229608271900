import NotificationIcon from '~/assets/img/web-notification-icon.png'

type AdvancedNotificationOptions = {
    title?: string
    autoCloseAfter?: number
    vibrate?: number[]
}

type UseWebNotification = {
    isSupported: Ref<boolean>
    isPermissionGranted: Ref<boolean>
    permission: Ref<NotificationPermission | false>
    notification: Ref<Notification | undefined>
    show: (overrides?: NotificationOptions & AdvancedNotificationOptions) => Promise<Notification | undefined>
    close: VoidFunction
    ensurePermissions: () => Promise<boolean>
    onShow: (fn: Function) => VoidFunction
    onClick: (fn: Function) => VoidFunction
    onClose: (fn: Function) => VoidFunction
    onError: (fn: Function) => VoidFunction
}

export const useWebNotification = (
    defaultOptions: NotificationOptions & AdvancedNotificationOptions = {}
): UseWebNotification => {
    const { window, document } = getClientContext()

    const hooks = {
        onShow: createEventHook(),
        onClick: createEventHook(),
        onClose: createEventHook(),
        onError: createEventHook()
    }

    const onShow = fn => hooks.onShow.on(fn)
    const onClick = fn => hooks.onClick.on(fn)
    const onClose = fn => hooks.onClose.on(fn)
    const onError = fn => hooks.onError.on(fn)

    tryOnScopeDispose(() => {
        Object.values(hooks).forEach(hook => hook.clear())
    })

    const { locale } = useLang()

    if (!defaultOptions.lang) {
        defaultOptions.lang = locale.value.nativeLocale
    }

    defaultOptions.icon = NotificationIcon

    const isSupported = useSupported(() => window && ('Notification' in window))
    const isPermissionGranted = ref<boolean>(isSupported.value && ('permission' in Notification) && (Notification.permission === 'granted'))
    const permission = ref<UseWebNotification['permission']>(isSupported.value && Notification.permission as any)
    const notification = ref<Notification | undefined>()

    const ensurePermissions = async (): Promise<boolean> => {
        if (!isSupported.value) {
            return
        }

        if (!isPermissionGranted.value && Notification?.permission !== 'denied') {
            if (await Notification.requestPermission() === 'granted') {
                isPermissionGranted.value = true
            }
        }

        return isPermissionGranted.value
    }

    const show = async (
        overrides?: NotificationOptions & AdvancedNotificationOptions
    ): Promise<Notification | undefined> => {
        if (!isSupported.value || !await ensurePermissions()) {
            return
        }

        const { title = '', ...options } = Object.assign({}, defaultOptions, overrides)

        const currentNotification = notification.value = new Notification(title, options)

        currentNotification.onshow = hooks.onShow.trigger
        currentNotification.onclick = hooks.onClick.trigger
        currentNotification.onclose = hooks.onClose.trigger
        currentNotification.onerror = hooks.onError.trigger

        if (options.autoCloseAfter) {
            setTimeout(() => currentNotification.close(), options.autoCloseAfter)
        }

        return currentNotification
    }

    const close = (): void => {
        if (notification.value) {
            notification.value.close()
        }

        notification.value = undefined
    }

    tryOnScopeDispose(close)

    if (isSupported.value && document) {
        onEvent(document, 'visibilitychange', (event: Event) => {
            event.preventDefault()

            if (document.visibilityState === 'visible') {
                close()
            }
        })
    }

    return {
        isSupported,
        isPermissionGranted,
        permission,
        notification,
        show,
        close,
        ensurePermissions,
        onShow,
        onClick,
        onClose,
        onError
    }
}
