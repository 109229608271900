import type { SearchParameters } from 'ofetch'
import type { UseAppFetchOptions } from '~/composables/useAppFetch'

export default (options: UseAppFetchOptions): SearchParameters | undefined => {
    if (!options.query) {
        return
    }

    return buildSearchParameters(options.query)
}
