import type { AppTablePagination } from '~/ts/types/app'
import type * as quickAnswerTypes from '~/ts/types/quick-answer'

export default {
    all({ page = 1, perPage = APP_DEFAULT_PER_PAGE }: quickAnswerTypes.QuickAnswerAll = {}) {
        return useAppFetch<{ items: quickAnswerTypes.QuickAnswer[], _meta: AppTablePagination }>('/project/{projectId}/message-template', {
            params: {
                page,
                'per-page': perPage
            }
        })
    },
    one({ id }: quickAnswerTypes.QuickAnswerOne) {
        return useAppFetch<quickAnswerTypes.QuickAnswer>(`/project/{projectId}/message-template/${ id }`, {
            params: { id }
        })
    },
    create({ data }: quickAnswerTypes.QuickAnswerCreate) {
        return useAppFetch<quickAnswerTypes.QuickAnswer>('/project/{projectId}/message-template', {
            method: 'post',
            body: data
        })
    },
    update({ id, data }: quickAnswerTypes.QuickAnswerUpdate) {
        return useAppFetch<quickAnswerTypes.QuickAnswer>(`/project/{projectId}/message-template/${ id }`, {
            method: 'put',
            body: data
        })
    },
    delete({ id }: quickAnswerTypes.QuickAnswerDelete) {
        return useAppFetch(`/project/{projectId}/message-template/${ id }`, {
            method: 'delete'
        })
    },
    tagList() {
        return useAppFetch<quickAnswerTypes.QuickAnswerTag[]>('/project/{projectId}/message-template/tags', {
            deep: true
        })
    }
}
