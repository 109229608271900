import type * as communicationChannelTypes from '~/ts/types/communication-channel'

export default {
    all({ page = 1, perPage = undefined, expand }: communicationChannelTypes.CChannelAll = {}) {
        return useAppFetch<{
            items: communicationChannelTypes.CChannel[]
        }>('/project/{projectId}/channel', {
            params: {
                expand: getFilledKeysString(expand),
                page,
                'per-page': perPage
            }
        })
    },
    one({ id, contentLanguage }: communicationChannelTypes.CChannelOne) {
        return useAppFetch<communicationChannelTypes.CChannel>(`/project/{projectId}/channel/${ id }`, {
            params: {
                id,
                content_language: contentLanguage
            }
        })
    },
    createTelegram({ data }: communicationChannelTypes.CChannelCreateTelegram) {
        return useAppFetch('/project/{projectId}/channel/telegram', {
            method: 'post',
            body: data
        })
    },
    createViber({ data }: communicationChannelTypes.CChannelCreateViber) {
        return useAppFetch('/project/{projectId}/channel/viber', {
            method: 'post',
            body: data
        })
    },
    update({ id, data }: communicationChannelTypes.CChannelUpdate) {
        return useAppFetch<communicationChannelTypes.CChannel>(`/project/{projectId}/channel/${ id }`, {
            method: 'put',
            body: data
        })
    },
    updateWidget({ id, data, contentLanguage }: communicationChannelTypes.CChannelUpdateWidget) {
        return useAppFetch(`/project/{projectId}/channel/${ id }/widget`, {
            method: 'put',
            params: { content_language: contentLanguage },
            body: data
        })
    },
    updateTelegram({ id, data }: communicationChannelTypes.CChannelUpdateTelegram) {
        return useAppFetch(`/project/{projectId}/channel/${ id }/telegram`, {
            method: 'put',
            body: data
        })
    },
    updateViber({ id, data }: communicationChannelTypes.CChannelUpdateViber) {
        return useAppFetch(`/project/{projectId}/channel/${ id }/viber`, {
            method: 'put',
            body: data
        })
    },
    delete({ id }: communicationChannelTypes.CChannelDelete) {
        return useAppFetch(`/project/{projectId}/channel/${ id }`, {
            method: 'delete'
        })
    },
    operators({ id, page = 1, perPage = undefined }: communicationChannelTypes.CChannelOperatorAll) {
        return useAppFetch<{
            items: communicationChannelTypes.CChannelOperatorsItem[]
        }>(`/project/{projectId}/channel/${ id }/operator`, {
            params: {
                page,
                'per-page': perPage
            }
        })
    },
    operatorUpdate({ channelId, id, data }: communicationChannelTypes.CChannelOperatorUpdate) {
        return useAppFetch(`/project/{projectId}/channel/${ channelId }/operator/${ id }`, {
            method: 'put',
            body: data
        })
    }
}
