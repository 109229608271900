import { defineStore } from 'pinia'
import { useUserStore } from '~/stores/user'
import type { Project } from '~/ts/types/project'
import { AppCustomSystemAlertTypeEnum } from '~/ts/enums/app'

export const useAppStore = defineStore('appStore', () => {
    const dataFetched = ref<boolean>(false)
    const showLoader = ref<boolean>(true)
    const showCongratulationsOnRegistrationModal = ref<boolean>(false)
    const showContentUpdatedModal = ref<boolean>(false)

    const userStore = useUserStore()

    const systemAlert = computed<Project['systemAlert'] | undefined>(() => userStore.currentProject?.systemAlert)

    const showBanner = computed<boolean>(() => {
        if (!systemAlert.value) {
            return false
        }

        if (!systemAlert.value.id) {
            return true
        }

        return systemAlert.value.id > +useAppCookie('banner:closed-alert-id').value
    })

    const bannerBgColor = computed<string | undefined>(() => {
        if (systemAlert.value?.type === AppCustomSystemAlertTypeEnum.AppEnableNotifications) {
            return '#f9de56'
        }

        const alertStyle = systemAlert.value?.content?.style

        if (!alertStyle) {
            return
        }

        return (alertStyle === 'danger') ? '#fae1db' : (alertStyle === 'warning') ? '#f9ead8' : '#dee6f0'
    })

    const removeCurrentSystemAlert = (): void => {
        if (systemAlert.value.id) {
            useAppCookie('banner:closed-alert-id').value = String(systemAlert.value.id)
        }

        userStore.currentProject.systemAlert = undefined
    }

    const setSystemAlertEnableNotificationsIfNecessary = (): void => {
        if (systemAlert.value) {
            return
        }

        const { tablet } = useWindowSize()

        if (!tablet.value) {
            return
        }

        const { isPermissionGranted } = useWebNotification()

        if (!isPermissionGranted.value) {
            userStore.currentProject.systemAlert = {
                type: AppCustomSystemAlertTypeEnum.AppEnableNotifications,
                can_close: 1
            }
        }
    }

    return {
        dataFetched,
        showLoader,
        systemAlert,
        showBanner,
        showCongratulationsOnRegistrationModal,
        showContentUpdatedModal,
        bannerBgColor,
        setSystemAlertEnableNotificationsIfNecessary,
        removeCurrentSystemAlert
    }
})
