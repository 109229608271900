export const DATE_DAYS: string[] = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ]
export const DATE_DAYS_SHORT: string[] = [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun' ]
export const DATE_DEFAULT_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'
export const DATE_DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
export const DATE_DISPLAY_DATETIME_FORMAT = 'dd.MM.yyyy HH:mm'
export const DATE_DISPLAY_DATE_FORMAT = 'dd.MM.yyyy'
export const DATE_DISPLAY_TIME_FORMAT = 'HH:mm'
export const DATE_DISPLAY_TIME_FULL_FORMAT = 'HH:mm:ss'
export const DATE_DISPLAY_DATETIME_ADVANCED_FORMAT = 'dd MMMM yyyy HH:mm'
export const DATE_DISPLAY_DATE_ADVANCED_FORMAT = 'dd MMMM yyyy'
