import type { Operator } from '~/ts/types/operator'

export default (operator: Operator, withEmail = false): string => {
    if (!operator?.user) {
        return '-'
    }

    const { user: { name, email } } = operator

    return withEmail
        ? (name ? `${ name } (${ email })` : email)
        : (name || email || '-')
}
