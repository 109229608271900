import type { SearchParameters } from 'ofetch'

export default (data: SearchParameters | undefined) => {
    const result = {}

    data = toValue(data)

    if (data) {
        for (const key in data) {
            result[key] = toValue(data[key])
        }
    }

    return result
}
