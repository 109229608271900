import type * as wsTypes from '~/ts/types/ws'
import { WSChannelEventEnum, WSClientChannelEventEnum, WSMessageActionEnum } from '~/ts/enums/ws'
import { Broadcaster } from '~/helpers/ws/Broadcaster'
import { BroadcasterMessageHandler } from '~/helpers/ws/BroadcasterMessageHandler'
import { Loggable } from '~/helpers/Loggable'

type ChannelEvent = WSChannelEventEnum | WSClientChannelEventEnum

export type BroadcasterChannelOptions = {
    immediatelySubscribe?: boolean
    isManualSubscription?: boolean
}

export class BroadcasterChannel extends Loggable {
    private listeners: Record<ChannelEvent, wsTypes.WSChannelListenerCallback[]> = {} as any
    private delayedMessages: string[] = []

    public isSubscribed = false
    public isManualSubscription = false

    constructor(
        private readonly broadcaster: Broadcaster,
        public readonly name: string,
        options: BroadcasterChannelOptions = { immediatelySubscribe: true }
    ) {
        super({
            disabled: true,
            defaultLogOptions: {
                hint: true,
                devOnly: true
            }
        })

        options.immediatelySubscribe && this.subscribe()

        if (options.isManualSubscription) {
            this.isManualSubscription = true
        }
    }

    public listen = (event: ChannelEvent, callback: wsTypes.WSChannelListenerCallback): this => {
        this.log(`(event: ${ event })`, 'BroadcasterChannel->listen')

        if (!this.listeners[event]) {
            this.listeners[event] = []
        }

        this.listeners[event].push(callback)

        return this
    }

    public stopListening = (event: ChannelEvent): void => {
        if (this.listeners[event]) {
            delete this.listeners[event]
        }
    }

    public triggerListener = (event: ChannelEvent, payload?: any): void => {
        this.log(`(event: ${ event })`, 'BroadcasterChannel->triggerListener')

        if (!this.listeners[event]) {
            return
        }

        this.listeners[event].forEach(cb => cb(payload))
    }

    public send = (event: WSClientChannelEventEnum, payload?: any): void => {
        this.log(`(event: ${ event })`, 'BroadcasterChannel->send')

        this.sendMessage({
            action: WSMessageActionEnum.PublishClient,
            channel: this.name,
            event,
            payload
        })
    }

    public subscribe = (): this => {
        this.log(`(name: ${ this.name })`, 'BroadcasterChannel->subscribe')

        if (!this.broadcaster.isConnected.value) {
            return this
        }

        if (!this.isSubscribed) {
            this.isSubscribed = true

            this.sendMessage({
                action: WSMessageActionEnum.Subscribe,
                channel: this.name
            })
        }

        this.processDelayedMessages()

        return this
    }

    public unsubscribe = (remove = false): void => {
        this.log(`(name: ${ this.name }, remove: ${ remove })`, 'BroadcasterChannel->unsubscribe')

        if (this.isSubscribed) {
            this.isSubscribed = false

            if (this.broadcaster.isConnected.value) {
                this.sendMessage({
                    action: WSMessageActionEnum.Unsubscribe,
                    channel: this.name
                })
            }
        }

        if (remove && this.broadcaster.channels.has(this.name)) {
            this.clearListeners()

            this.broadcaster.channels.delete(this.name)
        }
    }

    public clearListeners = (): void => {
        this.listeners = {} as any
    }

    private sendMessage = (message: wsTypes.WSMessage): void => {
        const serializedMessage = BroadcasterMessageHandler.serializeMessage(message)

        this.log(serializedMessage, 'BroadcasterChannel->sendMessage')

        if (this.broadcaster.isConnected.value) {
            this.broadcaster.ws.send(serializedMessage)
        } else {
            this.delayedMessages.push(serializedMessage)
        }
    }

    private processDelayedMessages = (): void => {
        this.log(this.delayedMessages, 'BroadcasterChannel->processDelayedMessages')

        if (!this.delayedMessages.length) {
            return
        }

        const messages = cloneValue(this.delayedMessages)

        this.delayedMessages = []

        messages.forEach(message => this.sendMessage(BroadcasterMessageHandler.deserializeMessage(message)))
    }
}
