<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M20 11V13H7.99997L13.5 18.5L12.08 19.92L4.15997 12L12.08 4.08002L13.5 5.50002L7.99997 11H20Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
