import type { AppTablePagination } from '~/ts/types/app'
import type * as operatorTypes from '~/ts/types/operator'

export default {
    all({ page = 1, perPage = APP_DEFAULT_PER_PAGE, expand }: operatorTypes.OperatorAll = {}) {
        return useAppFetch<{ items: operatorTypes.Operator[], _meta: AppTablePagination }>('/project/{projectId}/operator', {
            params: {
                expand: getFilledKeysString(expand),
                page,
                'per-page': perPage
            }
        })
    },
    one({ id, expand }: operatorTypes.OperatorOne) {
        return useAppFetch<operatorTypes.Operator>(`/project/{projectId}/operator/${ id }`, {
            params: {
                id,
                expand: getFilledKeysString(expand)
            }
        })
    },
    invite({ data }: operatorTypes.OperatorInvite) {
        return useAppFetch('/project/{projectId}/operator', {
            method: 'post',
            body: data
        })
    },
    inviteRepeat({ id }: operatorTypes.OperatorInviteRepeat) {
        return useAppFetch(`/project/{projectId}/operator/${ id }`, {
            method: 'post'
        })
    },
    update({ id, data }: operatorTypes.OperatorUpdate) {
        return useAppFetch<operatorTypes.Operator>(`/project/{projectId}/operator/${ id }`, {
            method: 'put',
            body: data
        })
    },
    delete({ id }: operatorTypes.OperatorDelete) {
        return useAppFetch(`/project/{projectId}/operator/${ id }`, {
            method: 'delete'
        })
    },
    updateSchedule({ operatorId, scheduleId, data }: operatorTypes.OperatorScheduleUpdate) {
        return useAppFetch<operatorTypes.Operator>(`/project/{projectId}/operator/${ operatorId }/schedule/${ scheduleId }`, {
            method: 'put',
            body: data
        })
    },
    updateOnboarding({ operatorId, data }: operatorTypes.OperatorOnboardingUpdate) {
        return useAppFetch<operatorTypes.Operator>(`/project/{projectId}/operator/${ operatorId }/onboarding`, {
            method: 'post',
            body: data
        })
    },
    analytics(params: operatorTypes.OperatorAnalyticsGet) { // params без деструктуризації зберігає реактивність, тому при оновленні та виклику refresh фетча params будуть актуальні
        return useAppFetch<operatorTypes.OperatorAnalytics>(`/project/{projectId}/operator/${ params.id }/analytics`, {
            params
        })
    },
    feedbacks({ id, query }: operatorTypes.OperatorFeedbackAll) {
        return useAppFetch<{
            items: operatorTypes.OperatorFeedbackItem[]
        }>(`/project/{projectId}/operator/${ id }/review`, {
            params: { id },
            query
        })
    },
    deleteFeedback({ id }: operatorTypes.OperatorFeedbackDelete) {
        return useAppFetch(`/project/{projectId}/operator/{operatorId}/review/${ id }`, {
            method: 'delete'
        })
    },
    updateOfflineStatus({ operatorId, data }: operatorTypes.OperatorOfflineStatusUpdate) {
        return useAppFetch<operatorTypes.Operator>(`/project/{projectId}/operator/${ operatorId }/offline`, {
            method: 'post',
            body: data
        })
    }
}
