<script
    lang="ts"
    setup
>
    import { useUserStore } from '~/stores/user'

    const userStore = useUserStore()
</script>

<template>
    <div
        v-if="true"
        class="
            z-[101]
            fixed
            top-0
            left-0
            w-full
            h-full
            flex
            flex-col
            items-center
            justify-center
            gap-4
            tablet:gap-8
            bg-[#F6F7F8]
        "
    >
        <div class="flex flex-col items-center w-[200px] tablet:w-[300px] h-[52px]">
            <video
                muted
                loop
                playsinline
                autoplay
                poster="~/assets/img/loader.png"
                preload="auto"
                draggable="false"
            >
                <source
                    src="~/assets/video/loader.webm"
                    type="video/webm"
                >

                <source
                    src="~/assets/video/loader.mp4"
                    type="video/mp4"
                >

                <span class="text-6xl">
                    Loading
                </span>
            </video>
        </div>

        <div
            v-if="userStore.currentProject"
            key="project"
            class="text-[20px] font-medium"
        >
            {{ userStore.currentProject.name }}
        </div>
    </div>
</template>
