import type { ChatMessage, ChatMessageDateGroup, ChatMessageSenderGroup } from '~/ts/types/chat'
import type { UseLang } from '~/composables/useLang'

export const GROUP_INTERVAL_SECONDS = 600 // 10 хвилин

export default (lang: UseLang, messages: ChatMessage[], firstUnreadId?: string): ChatMessageDateGroup[] => {
    if (!messages.length) {
        return []
    }

    const getSenderGroupTemplate = (): ChatMessageSenderGroup => ({
        key: '',
        senderKey: '',
        messages: []
    })

    const setKeysToSenderGroup = (senderGroup: ChatMessageSenderGroup, message: ChatMessage): void => {
        senderGroup.senderKey = message.sender_type + '-' + message.sender_id
        senderGroup.key = senderGroup.senderKey + '-' + stringUtil.generateHash()
    }

    let currentSenderGroup: ChatMessageSenderGroup = getSenderGroupTemplate()

    let currentDateGroup: ChatMessageDateGroup = {
        datetime: messages[0].created_at,
        dayStartAt: dateUtil.getDayStartAt(dateUtil.fromSeconds(messages[0].created_at)),
        senderGroups: [ currentSenderGroup ]
    }

    const dateGroups: ChatMessageDateGroup[] = [ currentDateGroup ]

    for (let index = 0, length = messages.length; index < length; index++) {
        const message = messages[index]
        const nextMessage = messages[index + 1]

        if (!currentSenderGroup.key) {
            setKeysToSenderGroup(currentSenderGroup, message)
        }

        currentSenderGroup.messages.push(message)

        if (!nextMessage) {
            break
        }

        const nextMessageDayStartsAt = dateUtil.getDayStartAt(dateUtil.fromSeconds(nextMessage.created_at))

        let resetSenderGroup = false

        if (currentDateGroup.dayStartAt !== nextMessageDayStartsAt) {
            resetSenderGroup = true

            currentDateGroup = {
                datetime: nextMessage.created_at,
                dayStartAt: nextMessageDayStartsAt,
                senderGroups: []
            }

            dateGroups.push(currentDateGroup)
        } else if (
            nextMessage._id === firstUnreadId
            || (message.sender_type !== nextMessage.sender_type)
            || (message.sender_id !== nextMessage.sender_id)
            || (nextMessage.created_at - message.created_at) > GROUP_INTERVAL_SECONDS
        ) {
            resetSenderGroup = true
        }

        if (resetSenderGroup) {
            currentSenderGroup = getSenderGroupTemplate()

            currentDateGroup.senderGroups.push(currentSenderGroup)
        }
    }

    return dateGroups
}
