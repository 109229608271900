import type { AnyFn } from '~/ts/types/common'

type Options = {
    interval?: boolean
    immediate?: boolean
    callAtStart?: boolean
}

export const useTimer = <Callback extends AnyFn>(fn: Callback, ms: number, options: Options = {}) => {
    const {
        interval = false,
        immediate = false,
        callAtStart = false
    } = options

    const pending = ref<boolean>(false)
    const setTimer = interval ? setInterval : setTimeout
    const clearTimer = interval ? clearInterval : clearTimeout

    const timerId = ref<ReturnType<typeof setTimer> | undefined>()
    let startTime: number | undefined
    let remaining = ms

    const clear = (): void => {
        if (!timerId.value) {
            return
        }

        clearTimer(timerId.value)

        timerId.value = undefined
        startTime = undefined
    }

    const stop = (): void => {
        pending.value = false

        clear()
    }

    const start = (...args: any[]): number => {
        pending.value = true

        if (!process.client) {
            return
        }

        clear()

        callAtStart && fn(...args)

        startTime = Date.now()

        timerId.value = setTimer(() => {
            !interval && stop()

            fn(...args)
        }, remaining)

        return remaining
    }

    const pause = (): void => {
        clearTimer(timerId.value)

        if (!interval) {
            remaining -= Date.now() - startTime
        }
    }

    if (immediate) {
        start()
    }

    tryOnScopeDispose(stop)

    return {
        id: timerId,
        pending: readonly(pending),
        start,
        pause,
        stop
    }
}
