// Має бути явно імпортовано через незрозумілу відсутність інколи
import { isString } from '~/utils/isString'

type JWT = {
    exp: number
    iat?: number
    nbf?: number
    uid?: number
}

const instanceOfJWT = (value: JWT | any): boolean => {
    return ('exp' in value) && isNumber(value.exp)
}

const b64DecodeUnicode = (string: string): string => {
    return decodeURIComponent(
        atob(string).replace(
            /(.)/g, (m, p) => {
                let code = p.charCodeAt(0).toString(16).toUpperCase()

                if (code.length < 2) {
                    code = '0' + code
                }

                return '%' + code
            }
        )
    )
}

const base64UrlDecode = (string: string): string => {
    let output = string
        .replace(/-/g, '+')
        .replace(/_/g, '/')

    switch (output.length % 4) {
        case 0:
            break
        case 2:
            output += '=='
            break
        case 3:
            output += '='
            break
        default:
            throw new Error('Invalid base64url string')
    }

    try {
        return b64DecodeUnicode(output)
    } catch (error) {
        return atob(output)
    }
}

export const decodeJWT = (token: string | unknown): JWT | undefined => {
    if (!isString(token)) {
        return undefined
    }

    try {
        return JSON.parse(base64UrlDecode(token.split('.')[1]))
    } catch (error) {
        return undefined
    }
}

export const validateJWT = (token: string | JWT | undefined): boolean => {
    if (isString(token)) {
        token = decodeJWT(token)
    }

    const val1 = isObject(token)
        && instanceOfJWT(token)
        && Math.floor(token.exp)

    const val2 = dateUtil.rawNow().plus({ second: 30 }).toUnixInteger()

    return isObject(token)
        && instanceOfJWT(token)
        && val1 > val2
}
