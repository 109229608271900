export enum AppItemPhoneCountryIndexEnum {
    Code = 0,
    Name = 1,
    DialCode = 2,
    Flag = 3
}

export enum AppItemRoleEnum {
    Admin = 1,
    Operator = 2
}

export enum AppCustomSystemAlertTypeEnum {
    AppEnableNotifications = 'app:enable_notifications'
}
