export type IsEmptyOptions = {
    primitive?: boolean
    trim?: boolean
}

export const isEmpty = (value: any, options: IsEmptyOptions = {}): boolean => {
    const {
        primitive = false,
        trim = true
    } = options

    if (isNull(value) || isUndefined(value)) {
        return true
    }

    if (isString(value)) {
        return trim ? !value.trim().length : !value.length
    }

    if (primitive) {
        return false
    }

    const rawValue = toRaw(value)

    if (isArray(rawValue)) {
        return !rawValue.length
    }

    if (isObject(rawValue)) {
        return !getObjectSize(rawValue)
    }

    return false
}
