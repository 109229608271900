<script
    lang="ts"
    setup
>
    type Props = {
        size?: number | string
        color?: string,
        viewBox?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        size: 24,
        color: '#aaadb8',
        viewBox: true
    })

    const style = computed<string>(() => `min-width: ${ props.size }px; min-height: ${ props.size }px;`)
</script>

<template>
    <svg
        :width="props.size"
        :height="props.size"
        :viewBox="props.viewBox ? '0 0 24 24' : null"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="style"
    >
        <slot :color="props.color"/>
    </svg>
</template>
