import type { MaybeRef } from '~/ts/types/common'

export const useMediaQuery = (query: MaybeRef<string>): Ref<boolean> => {
    const { window } = getClientContext()

    const isSupported = useSupported(() => (
        window
        && ('matchMedia' in window)
        && (typeof window.matchMedia === 'function')
    ))

    const matches = ref<boolean>(false)

    let mediaQuery: MediaQueryList | undefined

    const handler = (event: MediaQueryListEvent): void => {
        matches.value = event.matches
    }

    const cleanup = () => {
        if (!mediaQuery) {
            return
        }

        if ('removeEventListener' in mediaQuery) {
            mediaQuery.removeEventListener('change', handler)
        } else {
            // @ts-expect-error deprecated API
            mediaQuery.removeListener(handler)
        }
    }

    const stopWatch = watchEffect(() => {
        if (!isSupported.value) {
            return
        }

        cleanup()

        mediaQuery = window!.matchMedia(toValue(query))

        if ('addEventListener' in mediaQuery) {
            mediaQuery.addEventListener('change', handler)
        } else {
            // @ts-expect-error deprecated API
            mediaQuery.addListener(handler)
        }

        matches.value = mediaQuery.matches
    })

    tryOnScopeDispose(() => {
        stopWatch()
        cleanup()

        mediaQuery = undefined
    })

    return matches
}
