<script
    lang="ts"
    setup
>
    type Props = {
        tag?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        tag: 'div'
    })
</script>

<template>
    <component
        :is="props.tag"
        class="text-[14px] leading-[130%] text-[#8a8f9e]"
    >
        <slot/>
    </component>
</template>
